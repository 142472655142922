import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import MainLayout from "../MainLayout";
import ListCampaignComponent from "../../components/ListCampaign";
import Loading from "../../components/Loading";
import MessengerButton from "./../Home/items/MessengerButton";

const useStyles = makeStyles((theme) => ({}));
const CampaignView = (props) => {
  const { getCampaign, campaign, loading } = props;
  const [campaignData, setCampaignData] = useState([]);

  useEffect(() => {
    setCampaignData(campaign);
  }, [campaign]);

  const handleGetCampaign = (params) => {
    getCampaign(params);
  };

  return (
    <MainLayout>
      {loading && <Loading />}
      <ListCampaignComponent
        handleGetCampaign={(params) => handleGetCampaign(params)}
        getCampaign={(params) => getCampaign(params)}
        data={campaignData}
      />
      <MessengerButton />
    </MainLayout>
  );
};

CampaignView.propTypes = {};

CampaignView.defaultProps = {};

export default CampaignView;
