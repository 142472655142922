import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import DialogPolicyPointView from "./DialogPolicyPointView";
import * as selectorsApp from "../App/AppSelectors";
import * as actionsDialogPolicyPoint from "./DialogPolicyPointActions";
import * as selectorDialogPolicyPoint from "./DialogPolicyPointSelectors";

const mapStateToProps = state => {
  return {
    whatYouNeed: selectorDialogPolicyPoint.whatYouNeed(state),
    loading: selectorsApp.loading(state),
    redeemPoints: selectorDialogPolicyPoint.redeemPoints(state),
  };
};

const mapDispatchToProps = dispatch => ({
  onWhatYouNeed: (params) => dispatch(actionsDialogPolicyPoint.getWhatYouNeed(params)),
  onRedeemPoints: (params) => dispatch(actionsDialogPolicyPoint.getRedeemPoints(params)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DialogPolicyPointView));
