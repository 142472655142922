import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import IMAGES from "../../images";
import Container from "@material-ui/core/Container";
import ButtonComponent from "../../components/Button";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import EmailIcon from "@material-ui/icons/Email";
import LockIcon from "@material-ui/icons/Lock";
import FacebookIcon from "@material-ui/icons/Facebook";
import { pathKeys } from "../../constants";
import FormDialog from "../../components/FormDialog";
import Loading from "../../components/Loading";
import { useTranslation } from "react-i18next";
import Flex from "components/Flex";
import ChangeLanguage from "components/ChangeLanguage";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100vh",
    backgroundSize: "cover",
    background: "no-repeat top",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  wrapperLogin: {
    width: 445,
    minHeight: 500,
    border: "1px solid #f0f0f0",
    background: "#F7FAFC",
    borderRadius: ".375rem",
    paddingBottom: 15,
  },
  top: {
    borderBottom: "1px solid #f0f0f0",
    padding: "1.25rem 0 3rem 0",
    textAlign: "center",
  },
  bottomTitle: {
    marginBottom: 4,
    fontSize: 14,
    color: "#8898aa",
    textAlign: "center",
  },
  form: {
    width: "100%",
  },
  input: {
    marginBottom: 15,
  },
  button: {
    marginTop: 30,
  },
  facebook: {
    background: "white",
    "&:hover": {
      background: "#1877F2",
      color: "white",
      "& $iconFacebook": {
        color: "white",
      },
    },
  },
  iconFacebook: {
    marginRight: 5,
    display: "flex",
    color: "#1877F2",
  },
  error: {
    color: "red",
    textAlign: "center",
    marginBottom: 0,
  },
  forgotPassword: {
    color: "#05AE34",
    textAlign: "right",
    "&:hover": {
      cursor: "pointer",
      textDecoration: "underline",
    },
  },
  wrapChangePassword: {
    padding: "0px 15px 40px 15px",
  },
  titleChangePassword: {
    textAlign: "center",
    marginTop: 30,
    marginBottom: 15,
    color: "#05AE34",
  },
  inputForgotPassword: {
    width: "300px !important",
    marginRight: 15,
    position: "relative",
    top: 10,
  },
  wrapForgotPasswordInput: {
    textAlign: "center",
  },
  success: {
    color: "#05AE34",
    textAlign: "center",
  },
  error: {
    color: "red",
    textAlign: "center",
  },
  banner: {
    position:"fixed",
    top:30,
  }
}));

const LoginView = (props) => {
  const {
    login,
    getAccount,
    handleSubmitEmail,
    statusLogined,
    onSubmitForgotPassword,
    forgotPassword,
    loading,
  } = props;
  const classes = useStyles();
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isForgotPasswordPopup, setIsForgotPasswordPopup] = useState(false);
  const [emailForgotPassword, setEmailForgotPassword] = useState("");
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (getAccount.token) {
      history.push(pathKeys.HOME);
    }
  }, [getAccount]);

  useEffect(() => {
    if (forgotPassword) {
      setEmailForgotPassword("");
    }
  }, [forgotPassword]);

  const handleClickButtonFacebook = async () => {
    localStorage.removeItem("alertGiftBox");
    const searchParam = new URLSearchParams(window.location.search);
    const ref = searchParam.get("ref");
    login(ref);
  };

  const handleChange = (key, value) => {
    switch (key) {
      case "email":
        setEmail(value);
        break;
      case "password":
        setPassword(value);
        break;
      default:
        break;
    }
  };

  const handleSubmit = () => {
    const searchParam = new URLSearchParams(window.location.search);
    const ref = searchParam.get("ref");
    const payload = { email, password };
    if (ref) payload.ref = ref;

    handleSubmitEmail(payload);
  };

  const handleForgotPasswordPopup = () => {
    setIsForgotPasswordPopup(true);
  };

  const handleCloseForgotPasswordPopup = () => {
    setIsForgotPasswordPopup(false);
  };
  const handleForgotPassword = () => {
    if (emailForgotPassword) {
      const params = {
        email: emailForgotPassword,
      };
      onSubmitForgotPassword(params);
    }
  };

  const handleChangeForgotPassword = (key, value) => {
    switch (key) {
      case "emailForgotPassword":
        setEmailForgotPassword(value);
        break;

      default:
        break;
    }
  };

  const background = useMemo(() => {
    return i18n.language?.substr(0, 2) === "vi"
      ? IMAGES.BACKGROUND
      : IMAGES.BACKGROUND_INTE;
  }, [i18n.language]);
  return (
    <div
      className={classes.root}
      style={{ backgroundImage: `url(${background})` }}
    >
      {loading && <Loading />}
      <div className={classes.wrapperLogin}>
        <Flex column justify="space-between" style={{ height: 500 }}>
          <Container fixed>
            <div className={classes.top}>
              <p className="mb-1">{t("login.login")}</p>
              <ButtonComponent
                className={classes.facebook}
                title={"Facebook"}
                handleClickButton={() => handleClickButtonFacebook()}
                icon={
                  <span className={classes.iconFacebook}>
                    <FacebookIcon />
                  </span>
                }
              />
            </div>
            <div className={classes.bottom}>
              <p className={classes.bottomTitle}>
                {t("login.login_by_account")}
              </p>
              <FormControl className={classes.form}>
                <Input
                  className={classes.input}
                  id="input-name"
                  label="Standard"
                  variant="outlined"
                  placeholder={"Email"}
                  value={email}
                  onChange={(event) =>
                    handleChange("email", event.target.value)
                  }
                  startAdornment={
                    <InputAdornment position="start" label="Standard">
                      <EmailIcon />
                    </InputAdornment>
                  }
                />
                <Input
                  className={classes.input}
                  id="input-password"
                  type="password"
                  placeholder={t("update.form.plh_pass")}
                  value={password}
                  onChange={(event) =>
                    handleChange("password", event.target.value)
                  }
                  startAdornment={
                    <InputAdornment position="start">
                      <LockIcon />
                    </InputAdornment>
                  }
                />
                <span
                  className={classes.forgotPassword}
                  onClick={() => handleForgotPasswordPopup()}
                >
                  {t("login.forgot_password.title")}
                </span>
                {!statusLogined && statusLogined != null && (
                  <p className={classes.error}>{t("noti.login")}</p>
                )}
                <ButtonComponent
                  className="btn--rounder mt-3"
                  title={t("common.btn.login")}
                  backgroundColor="#05AE34"
                  color="white"
                  handleClickButton={() => handleSubmit()}
                />
              </FormControl>
            </div>
          </Container>
          <Flex justify="center" className="w-100">
            <ChangeLanguage />
          </Flex>
        </Flex>
      </div>

      <FormDialog
        isOpen={isForgotPasswordPopup}
        handleClose={() => handleCloseForgotPasswordPopup()}
      >
        <div className={classes.wrapChangePassword}>
          <h4 className={classes.titleChangePassword}>
            {t("login.forgot_password.title")}
          </h4>
          <p style={{ textAlign: "center" }}>
            {t("login.forgot_password.description")}
          </p>
          {forgotPassword != null && !forgotPassword && (
            <p className={classes.error}>{t("noti.email_not_exist")}</p>
          )}
          {forgotPassword != null && forgotPassword && (
            <p className={classes.success}>{t("noti.reset_password")}</p>
          )}
          <div className={classes.wrapForgotPasswordInput}>
            <Input
              className={classes.inputForgotPassword}
              id="input-name"
              label="Standard"
              placeholder={"Email"}
              value={emailForgotPassword}
              onChange={(event) =>
                handleChangeForgotPassword(
                  "emailForgotPassword",
                  event.target.value
                )
              }
              startAdornment={
                <InputAdornment position="start" label="Standard">
                  <EmailIcon />
                </InputAdornment>
              }
            />
            <ButtonComponent
              className="btn--rounder"
              title={t("common.btn.confirm")}
              backgroundColor="#FF8000"
              color="white"
              handleClickButton={() => handleForgotPassword()}
            />
          </div>
        </div>
      </FormDialog>
    </div>
  );
};

LoginView.propTypes = {};

LoginView.defaultProps = {};

export default LoginView;
