import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import CustomerView from "./CustomerView";
import * as actionsFanpage from "../Fanpage/FanpageActions";
import * as actionsCustomer from "./CustomerActions";
import * as selectorsFanpage from "../Fanpage/FanpageSelectors";
import * as selectorsCustomer from "./CustomerSelectors";
import * as selectorsApp from "../App/AppSelectors";
const mapStateToProps = state => {
  return {
    fanpageFull: selectorsFanpage.fanpageFull(state),
    customer: selectorsCustomer.getCustomer(state),
    edit: selectorsCustomer.edit(state),
    detail: selectorsCustomer.getDetail(state),
    getListTagFacebook: selectorsCustomer.getListTagFacebook(state),
    getListTagBombot: selectorsCustomer.getListTagBombot(state),
    fanpageSelected: selectorsCustomer.fanpageSelected(state),
    tagFacebookSelectedAll: selectorsCustomer.tagFacebookSelectedAll(state),
    customerAll: selectorsCustomer.getCustomerAll(state),
    customerCheck: selectorsCustomer.customerCheck(state),
    loadingImport: selectorsCustomer.loadingImport(state),
    importSuccess: selectorsCustomer.importSuccess(state),
    createCustomerSucss: selectorsCustomer.createCustomerSucss(state),
    messageImport: selectorsCustomer.messageImport(state),
    loading: selectorsApp.loading(state),
    
    
  };
};

const mapDispatchToProps = dispatch => ({
  getFanpage: (params) => dispatch(actionsFanpage.getFanpageFull(params)),
  getCustomer: (params) => dispatch(actionsCustomer.getCustomer(params)),
  resetCustomer: () => dispatch(actionsCustomer.resetCustomer()),
  getCustomerAll: (params) => dispatch(actionsCustomer.getCustomerAll(params)),
  onDetail: (params) => dispatch(actionsCustomer.detail(params)),
  onEdit: (params) => dispatch(actionsCustomer.edit(params)),
  onFanpageSelected: (params) => dispatch(actionsCustomer.fanpageSelected(params)),
  onTagFacebookSelectedAll: (params) => dispatch(actionsCustomer.tagFacebookSelectedAll(params)),
  onSelectTagFacebook: (params) => dispatch(actionsCustomer.selectTagFacebook(params)),
  onExport: (params) => dispatch(actionsCustomer.onExport(params)),
  fileUpload: (params) => dispatch(actionsCustomer.fileUpload(params)),
  downloadExcel: (params) => dispatch(actionsCustomer.downloadExcel(params)),
  onSubmitAddTag: (params) => dispatch(actionsCustomer.onSubmitAddTag(params)),
  onCreate: (paramsFilter, rows, params, data) => dispatch(actionsCustomer.onCreate(paramsFilter, rows, params, data)),
  onCustomerCheck: (params) => dispatch(actionsCustomer.onCustomerCheck(params)),
  onCustomerCheckReset: (params) => dispatch(actionsCustomer.onCustomerCheckReset(params)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CustomerView));
