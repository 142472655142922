import { createSlice } from "@reduxjs/toolkit";
import initialState from "../../configs/redux/initialState";

const dialogPolicyPointReducer = createSlice({
  name: "dialogPolicyPointReducer",
  initialState: initialState.dialogPolicyPointReducer,
  reducers: {
    whatYouNeed: (state, action) => {
      state.whatYouNeed = action.payload;
    },
    redeemPoints: (state, action) => {
      state.redeemPoints = action.payload;
    }
  }
});

export const {
    whatYouNeed,
    redeemPoints
} = dialogPolicyPointReducer.actions;

export default dialogPolicyPointReducer.reducer;
