import React from "react";
import FormDialog from "../../../components/FormDialog";

import CheckCircleOutlineRoundedIcon from "@material-ui/icons/CheckCircleOutlineRounded";
import { Button, DialogActions, DialogContent } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { clearImportResult } from "../CustomerReducer";
import Flex from "components/Flex";

const ModalResultImport = ({ data }) => {
  const dispatch = useDispatch();

  const handleClosePoup = () => {
    dispatch(clearImportResult());
  };
  return (
    <FormDialog isOpen={true} width={350} handleClose={() => handleClosePoup()}>
      <DialogContent>
        <Flex justify="center" align="center" column>
          <CheckCircleOutlineRoundedIcon className="fz-56 text-success" />
          <p className="text-success mt-1 fz-16">Đã import khách hàng!</p>
        </Flex>
        <div className="mt-4">
          <p>Thành công: {data?.updateSuccessful || 0} </p>
          <p>Thất bại: {data?.updateFailed || 0}</p>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClosePoup}
          size="small"
          className="btn--rounder btn--rounder--sm btn--primary"
        >
          OK
        </Button>
      </DialogActions>
    </FormDialog>
  );
};

export default ModalResultImport;
