import { createSlice } from "@reduxjs/toolkit";
import initialState from "../../configs/redux/initialState";

// IMPORTANT: Note that with @reduxjs/toolkit, you can change state directly.

const appReducer = createSlice({
  name: "app",
  initialState: {
    ...initialState.appReducer,
    share: null,
  },
  reducers: {
    showLoading(state, action) {
      state.loading = true;
    },
    hideLoading(state, action) {
      state.loading = false;
    },
    setUser(state, action) {
      state.user = action.payload;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setShare(state, action) {
      state.share = action.payload;
    },
  },
});

export const { showLoading, hideLoading, setUser, setLoading, setShare } =
  appReducer.actions;

export default appReducer.reducer;
