import i18n from "utils/i18n";
import axiosClient from "./axiosClient";
import urlApi from "./url";
const whatYouNeedApi = {
  whatYouNeed: (params) => {
    return axiosClient.get(`${urlApi.shared.getWhatYouNeed}`, {
      params: {
        ...params,
        lang:
          i18n?.language === "en-US" || !i18n?.language ? "en" : i18n?.language,
      },
    });
  },
  redeemPoints: (params) => {
    return axiosClient.get(`${urlApi.user.redeemPoints}`, {
      params: {
        ...params,
        lang:
          i18n?.language === "en-US" || !i18n?.language ? "en" : i18n?.language,
      },
    });
  },
};

export default whatYouNeedApi;
