import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import CampaignView from "./CampaignView";
import * as actionsCampaign from "./CampaignActions";
import * as selectorsCampaign from "./CampaignSelectors";
import * as selectorsApp from "../App/AppSelectors";
const mapStateToProps = state => {
  return {
    campaign: selectorsCampaign.campaign(state),
    loading: selectorsApp.loading(state),
  };
};

const mapDispatchToProps = dispatch => ({
  getCampaign: (params) => dispatch(actionsCampaign.getCampaign(params))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CampaignView));
