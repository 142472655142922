export function initFacebookSdk() {
    return new Promise(resolve => {
      
        window.fbAsyncInit = function() {
            window.FB.init({
              // appId: '479697989872942', //AppId Dev local
              appId: '4087907114572119', // AppId Bombot
              cookie: true,
              xfbml: true,
              version: "v7.0"
            });
          };
          
          (function(d, s, id) {
            let js;
            const fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) {
              return;
            }
            js = d.createElement(s);
            js.id = id;
            js.src = "https://connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
          })(document, "script", "facebook-jssdk"); 

    });
}