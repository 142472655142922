import React from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import cn from "classnames";

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: 15,
    height: 40,
  },
  disabled: {
    background: "#BDBDBD !important",
  },
}));

export default function ButtonComponent(props) {
  const {
    title,
    backgroundColor,
    color,
    className,
    icon,
    handleClickButton,
    times,
    disabled,
    size,
    ...rest
  } = props;
  const classes = useStyles();

  return (
    <Button
      {...rest}
      size={size || "small"}
      variant="contained"
      className={cn("btn--rounder", "text-uppercase")}
      className={cn(
        className,
        classes.root,
        disabled && classes.disabled,
        "btn--rounder"
      )}
      style={{ background: backgroundColor, color: color }}
      onClick={handleClickButton}
      disabled={disabled}
    >
      {icon && icon}
      {title}
      {_.isNumber(times) && <span style={{ marginLeft: 5 }}>({times})</span>}
    </Button>
  );
}

ButtonComponent.propTypes = {
  times: PropTypes.number,
  disabled: PropTypes.bool,
};

ButtonComponent.defaultProps = {
  times: null,
  disabled: false,
};
