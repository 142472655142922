import { createSlice } from "@reduxjs/toolkit";
import initialState from "../../configs/redux/initialState";

const inviteReducer = createSlice({
  name: "inviteReducer",
  initialState: initialState.inviteReducer,
  reducers: {
    
    setConfirmInviteCode: (state, action) => {
      state.confirmInviteCode = action.payload;
    },
  }
});

export const {
  setConfirmInviteCode
} = inviteReducer.actions;

export default inviteReducer.reducer;
