
// import { history } from "../../configs/history";
import {
    setMenu,
    setDehaze
} from "./SidebarReducer";

export function menu(menu) {
  return dispatch => {
    return dispatch(setMenu(menu));
  
  };
}
export function onDehaze(value) {
  return dispatch => {
    return dispatch(setDehaze(value));
  
  };
}
