import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import SidebarView from "./SidebarView";
import * as actionsSidebar from "./SidebarActions";
import * as selectorsSidebar from "./SidebarSelectors";
const mapStateToProps = state => {
  return {
    getMenu: selectorsSidebar.getMenu(state),
    dehaze: selectorsSidebar.dehaze(state),
  };
};

const mapDispatchToProps = dispatch => ({
  onMenu: (data) => dispatch(actionsSidebar.menu(data)),
  onDehaze: (value) => dispatch(actionsSidebar.onDehaze(value)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SidebarView));
