import React, { useEffect, useState } from "react";
import MainLayout from "../MainLayout";
import ListFanpageComponent from "../../components/ListFanpage";
import Loading from "../../components/Loading";
import MessengerButton from "./../Home/items/MessengerButton";

const FanpageView = (props) => {
  const { getFanpage, fanpage, user, loading } = props;
  const [data, setData] = useState([]);

  useEffect(() => {
    setData(fanpage);
  }, [fanpage]);

  const handleGetData = (params) => {
    getFanpage(params);
  };

  return (
    <MainLayout>
      {loading && <Loading />}
      <ListFanpageComponent
        handleGetData={(params) => handleGetData(params)}
        getData={(params) => getFanpage(params)}
        data={data}
        user={user}
      />
      <MessengerButton />
    </MainLayout>
  );
};

FanpageView.propTypes = {};

FanpageView.defaultProps = {};

export default FanpageView;
