import { createSelector } from "@reduxjs/toolkit";
export const account = state => state.loginReducer.account;
export const statusLogined = state => state.loginReducer.statusLogined;
export const forgotPassword = state => state.loginReducer.forgotPassword;

export const getAccount = createSelector(
  [account],
  (account) => {
    return account;
  }
);
