import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import FanpageView from "./FanpageView";
import * as actionsFanpage from "./FanpageActions";
import * as selectorsFanpage from "./FanpageSelectors";
import * as selectorsApp from "../App/AppSelectors";

const mapStateToProps = state => {
  return {
    fanpage: selectorsFanpage.fanpage(state),
    user: selectorsApp.user(state),
    loading: selectorsApp.loading(state),
  };
};

const mapDispatchToProps = dispatch => ({
  getFanpage: (params) => dispatch(actionsFanpage.getFanpage(params))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FanpageView));
