import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import _ from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import cn from "classnames";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import SettingsIcon from "@material-ui/icons/Settings";
import SearchIcon from "@material-ui/icons/Search";
import InputAdornment from "@material-ui/core/InputAdornment";
import Input from "@material-ui/core/Input";
import Checkbox from "@material-ui/core/Checkbox";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import EditIcon from "@material-ui/icons/Edit";
import moment from "moment";
import types from "../constants/types";
import ButtonComponent from "./Button";
import FilterListIcon from "@material-ui/icons/FilterList";
import Pagination from "@material-ui/lab/Pagination";
import FormDialog from "./FormDialog";
import TextField from "@material-ui/core/TextField";
import Flex from "./Flex";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ModalTagsBombot from "../components/Modals/ModalTagsBombot";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import GetAppIcon from "@material-ui/icons/GetApp";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import MuiAlert from "@material-ui/lab/Alert";
import { Snackbar, TablePagination } from "@material-ui/core";
import FilterCustomerModal from "./FilterCustomer";
import { useSelector } from "react-redux";
import { selectFilterCus } from "views/Customer/CustomerSelectors";
import useEffectIgnoreFirst from "hook/useEffectIgnoreFirst";
import { useTranslation } from "react-i18next";

function Alert(props) {
  return <MuiAlert elevation={6} variant='filled' {...props} />;
}

const headCells = [
  // {
  //   id: "actions",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Thao tác",
  // },
  {
    id: "id",
    numeric: false,
    disablePadding: false,
    label: "customer_management.list.uid",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "customer_management.list.facebook_name",
    width: 100,
  },
  {
    id: "gt",
    numeric: false,
    disablePadding: false,
    label: "customer_management.list.gender",
  },
  {
    id: "timeInbox",
    numeric: false,
    disablePadding: false,
    label: "customer_management.list.last_inbox_time",
  },
  {
    id: "phone",
    numeric: false,
    disablePadding: false,
    label: "customer_management.list.phone",
  },
  {
    id: "tagF",
    numeric: false,
    disablePadding: false,
    label: "customer_management.list.facebook_tag",
  },
  {
    id: "tagB",
    numeric: false,
    disablePadding: false,
    label: "customer_management.list.bombot_tag",
  },
  {
    id: "TimeLast",
    numeric: false,
    disablePadding: false,
    label: "customer_management.list.last_update_time",
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: false,
    label: "customer_management.list.action",
  },
];

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    numSelected,
    rowCount,
    checkBoxSelectAll,
    isEmptyCheckItem,
  } = props;
  const { t } = useTranslation();
  return (
    <TableHead>
      <TableRow>
        <TableCell padding='checkbox'>
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={isEmptyCheckItem.length > 0 ? false : checkBoxSelectAll}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            style={{ width: "12.5%" }}
          >
            <TableSortLabel hideSortIcon={true}>
              {t(headCell.label)}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: "15px 15px 60px 0px",
    "& .MuiTableCell-paddingCheckbox": {
      width: 130,
    },
  },
  paper: {
    width: "100%",
    marginBottom: "80px",
  },
  table: {
    minWidth: "100%",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  ".pagination": {
    "& .MuiTablePagination-input": {
      width: 70,
    },
  },

  wrapperSearch: {
    position: "relative",
  },

  buttonIEA: {
    width: "100%",
    // marginTop: 10,
    textAlign: "left",
    marginRight: "15px",
    padding: "20px 15px 15px 0px",
    position: "fixed",
    bottom: 60,
    background: "#F6F9FC",
    "& button": {
      marginRight: 15,
    },
  },
  noData: {
    color: "rgba(0,0,0,.5)",
    fontWeight: "bold",
    textAlign: "center",
    paddingBottom: 15,
  },
  iconCheck: {
    "& .MuiSvgIcon-root": {
      width: "40",
      height: "40",
    },
  },
  icon: {
    marginLeft: 5,
    cursor: "pointer",
    "& .MuiSvgIcon-root": {
      width: "40",
      height: "40",
    },
  },
  classTags: {
    "&:last-child": {
      "& span": {
        display: "none",
      },
    },
  },
  wrapBox: {
    padding: "15px",
    marginBottom: 30,
  },
  inputAdd: {
    width: "100%",
    marginBottom: 15,
  },
  wrapButton: {
    textAlign: "center",
  },
  buttonLoadMore: {
    border: "0px",
    color: "var(--primary-color) !important",
    backgroundColor: "unset"
  }
}));
let timeout = 0;
export default function EnhancedTable(props) {
  const {
    data,
    handleGetData,
    getData,
    isPagination,
    isSearch,
    fanpageSelected,
    detail,
    edit,
    handleExport,
    handleImport,
    handleFilter,
    onCreate,
    customerAll,
    onCustomerCheck,
    onCustomerCheckReset,
    customerCheck,
    createCustomerSucss,
    paramsFilter,
    paramsPagination,
  } = props;
  // const rows = (data && data.content) || [];
  const classes = useStyles();
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(types.PAGINATION.PAGE);
  const [dense, setDense] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(types.PAGINATION.SIZE);
  const [search, setSearch] = useState("");
  const [checkBoxSelectAll, setCheckBoxSelectAll] = useState(true);
  const [rows, setRows] = useState([]);
  const [isCreateCampaign, setIsCreateCampaign] = useState(false);
  const [titleCampaign, setTitleCampaign] = useState("");
  const [isCheckedAllPage, setIsCheckedAllPage] = useState(true);

  const [isUseCheckedAllPage, setIsUseCheckedAllPage] = useState(true);


  const [customerAllState, setCustomerAllState] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [typeModalTag, setTypeModalTag] = useState(null);
  const [error, setError] = useState("");
  const [typeMessage, setTypeMessage] = useState(null);
  const [customerAfterFilter, setcustomerAfterFilter] = useState([]);
  const [showModalAllTags, setshowModalAllTags] = useState(false);
  const [selectedModalTags, setSelectedModalTags] = useState([]);

  const history = useHistory();

  useEffect(() => {
    const searchParam = new URLSearchParams(window.location.search);
    const page = searchParam.get("page");
    if (page) {
      setPage(_.toNumber(page) + 1);
    }
  }, [paramsFilter]);
  useEffect(() => {
    if (createCustomerSucss) {
      setIsCreateCampaign(false);
    }
  }, [createCustomerSucss]);
  useEffect(() => {
    setCustomerAllState(customerAll);
  }, [customerAll]);

  let totalNumber = data && data.totalElements - customerCheck.length;

  useEffect(() => {
    setRows((data && data.content) || []);

  }, [data]);

  useEffect(() => {
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      handleSubmitSearch();
    }, 300);
  }, [search, fanpageSelected]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getData({
      page: newPage,
      size: rowsPerPage,
      search: `pageId==${fanpageSelected.id};${search && `uid|name==` + search
        }`,
      sort: "lastInboxTime,desc",
    });
  };



  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, types.PAGINATION.SIZE));
    setPage(types.PAGINATION.PAGE);
    getData({
      page: types.PAGINATION.PAGE,
      size: parseInt(event.target.value, types.PAGINATION.SIZE),
      search: `pageId==${fanpageSelected.id};${search && `uid|name==` + search
        }`,
      sort: "lastInboxTime,desc",
    });
    const searchParam = new URLSearchParams(window.location.search);
    searchParam.set(
      "size",
      parseInt(event.target.value, types.PAGINATION.SIZE)
    );
    const newSearchParam = searchParam.toString();
    history.push({
      pathname: window.location.pathname,
      search: newSearchParam,
    });
  };

  const filterCus = useSelector(selectFilterCus);
  const handleChange = (event, value) => {
    const searchParam = new URLSearchParams(window.location.search);
    searchParam.set("page", value - 1);
    const search = searchParam.get("search");
    const newSearchParam = searchParam.toString();
    history.push({
      pathname: window.location.pathname,
      search: newSearchParam,
    });
    paramsPagination({
      page: value,
      size: rowsPerPage,
      pageId: fanpageSelected.id,
    });
    //setCheckBoxSelectAll(true);
    setPage(value);
    if (filterCus?.search_string) {
      getData({
        page: value - 1,
        size: 10,
        search: filterCus?.search_string,
        sort: "lastInboxTime,desc",
      });
    } else {
      getData({
        page: value - 1,
        size: rowsPerPage,
        search: `${search
          ? search
          : `pageId==${fanpageSelected.id};${search && `uid|name==` + search}`
          }`,
        sort: "lastInboxTime,desc",
      });
    }
  };
  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const handleSearch = (key, value) => {
    switch (key) {
      case "search":
        setSearch(value);
        if (value) {
          const customerFilter = customerAll.filter((cus) => (cus.uid && cus.uid.includes(value)) || (cus.nameText && cus.nameText.includes(value)));
          setcustomerAfterFilter(customerFilter);
        }
        break;
      default:
        break;
    }
    // setTypeMessage("error");
  };

  const handleSubmitSearch = () => {
    if (fanpageSelected?.id) {
      const searchParam = new URLSearchParams(window.location.search);
      searchParam.set("page", types.PAGINATION.PAGE);
      searchParam.set("size", types.PAGINATION.SIZE);
      searchParam.set("pageId", `${fanpageSelected.id}`);
      searchParam.set("sort", "lastInboxTime,desc");
      const getSearch = searchParam.get("search");
      const newSearchParam = searchParam.toString();
      history.push({
        pathname: window.location.pathname,
        search: newSearchParam,
      });
      paramsPagination({
        page: types.PAGINATION.PAGE,
        size: types.PAGINATION.SIZE,
        pageId: fanpageSelected.id,
      });
      if (!_.isEmpty(fanpageSelected)) {
        const params = {
          page: types.PAGINATION.PAGE,
          size: types.PAGINATION.SIZE,
          search: `${getSearch ? getSearch : `pageId==${fanpageSelected.id}`};${search && `uid|name==` + search
            }`,
          sort: "lastInboxTime,desc",
        };
        getData(params);
        setRowsPerPage(types.PAGINATION.SIZE);
        setPage(types.PAGINATION.PAGE);
      }
    }
  };

  const [isOpenModalFilter, setIsOpenModalFilter] = useState(false);

  const handleSubmitFilter = () => {
    setIsOpenModalFilter(true);
  };

  const toggleModalFilter = () => {
    setIsOpenModalFilter(!isOpenModalFilter);
  };
  const onSubmitFilter = (data) => {
    setcustomerAfterFilter(data)
  };
  const handleDetail = (item) => {
    detail(item);
  };

  const handleEdit = (item) => {
    const searchParam = new URLSearchParams(window.location.search);
    const getSearch = searchParam.get("search");
    const params = {
      page: types.PAGINATION.PAGE,
      size: types.PAGINATION.SIZE,
      search: `${getSearch ? getSearch : `pageId==${fanpageSelected.id}`};${search && `uid|name==` + search
        }`,
      sort: "lastInboxTime,desc",
    };
    edit(item, params);
  };
  const handleSubmitExport = () => {
    handleExport();
  };
  const handleSubmitImport = () => {
    handleImport();
  };
  const handleOpenExtention = () => {
    window.open(
      types.URL.FACEBOOK + `&token=${localStorage.getItem("token") || null}`,
      "_blank"
    );
  };
  const onKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSubmitSearch();
    }
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  
  const handleSelectAllClick = () => {
    let items = [];
    const checkArr = data && data.content.find((item) => item.checked == false);
    if (checkBoxSelectAll && _.isEmpty(checkArr)) {
      ((rows) || []).map((item) => {
        onCustomerCheck({ ...item, checked: true });
        return items.push({
          ...item,
          checked: true,
        });
      });
      setCheckBoxSelectAll(false);
    } else {
      ((rows) || []).map((item) => {
        onCustomerCheck({ ...item, checked: false });
        return items.push({
          ...item,
          checked: false,
        });
      });
      setCheckBoxSelectAll(true);
    }
    setRows(items);
  };

  const handleSelectCheckBox = (value) => {
    let items = [];
    ((rows) || []).map((item) => {
      if (item.id == value.id && value.checked == true) {
        onCustomerCheck(item);
        return items.push({
          ...item,
          checked: false,
        });
      } else if (item.id == value.id && value.checked == false) {
        onCustomerCheck(item);
        return items.push({
          ...item,
          checked: true,
        });
      } else {
        return items.push({
          ...item,
        });
      }
    });
    setRows(items);

  };
  const handleSubmitCreate = () => {
    setIsCreateCampaign(true);
  };
  const handleCloseCreatePopup = () => {
    setIsCreateCampaign(false);
    setError("");
    setTitleCampaign("");
  };

  const handleSubmitCreateCampaign = () => {
    const params = {
      name: titleCampaign,
      pageId: fanpageSelected.id,
    };
    if (titleCampaign) {
      onCreate(paramsFilter, customerCheck, params, data);
      setError("");
      setTitleCampaign("");
      setIsCreateCampaign(false);
    } else {
      setError(t("update.error_msg_name_camp"));
    }
  };

  const handleChangeCampaign = (key, value) => {
    switch (key) {
      case "titleCampaign":
        setTitleCampaign(value);
        break;
      default:
        break;
    }
  };
  const handleCheckedAllPage = () => {
    setIsUseCheckedAllPage(true);
    let items = [];
    let customerAll = [];
    if (isCheckedAllPage && customerCheck.length == 0) {
      ((data && data.content) || []).map((item) => {
        return items.push({
          ...item,
          checked: false,
        });
      });
      customerAllState.map((item) => {
        return customerAll.push({
          ...item,
          checked: false,
        });
      });
      onCustomerCheckReset(customerAllState);
      setIsCheckedAllPage(false);
      setCheckBoxSelectAll(false);
    } else {
      ((data && data.content) || []).map((item) => {
        return items.push({
          ...item,
          checked: true,
        });
      });
      customerAllState.map((item) => {
        return customerAll.push({
          ...item,
          checked: true,
        });
      });
      onCustomerCheckReset([]);
      setIsCheckedAllPage(true);
      setCheckBoxSelectAll(true);
    }
    setCustomerAllState(customerAllState);

    setRows(items);
  };
  let isEmptyCheckItem = [];
  rows.map((item) => {
    if (!item.checked) {
      isEmptyCheckItem.push(item);
    }
  });

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  

  const openModalTag = (type) => {
    setTypeModalTag(type);
    handleCloseMenu();
  };

  const onCloseModal = () => {
    setTypeModalTag(null);
  };

  const handleShowMoreTags = (tags) => {
    setshowModalAllTags(true);
    setSelectedModalTags(tags);
  };
  const handleCloseShowModalAllTagsPopup = (tags) => {
    setshowModalAllTags(false);
    setSelectedModalTags([]);
  };

  console.log('rows', rows)
  console.log('fanpageSelected', fanpageSelected)

  const { t } = useTranslation();
  return (
    <>
      {typeMessage && (
        <Snackbar
          open={typeMessage != null}
          anchorOrigin={{
            horizontal: "center",
            vertical: "top",
          }}
          autoHideDuration={6000}
        // onClose={() => setTypeMessage(null)}
        >
          <Alert onClose={() => { }} severity={typeMessage}>
            {typeMessage === "error"
              ? t("update.error_msg")
              : t("customer_management.import_customer.import_result.success")}
          </Alert>
        </Snackbar>
      )}
      <FormDialog
        isOpen={isCreateCampaign}
        handleClose={() => handleCloseCreatePopup()}
      >
        <div className='p-4'>
          <h4 className={classes.title}>
            {t("customer_management.create_campaign.title")}
          </h4>
          <TextField
            error={false}
            label={t(
              "customer_management.create_campaign.create_campaign_name"
            )}
            variant='outlined'
            size='small'
            className={cn("w-100", "my-4")}
            // defaultValue={addTag}
            onChange={(event) =>
              handleChangeCampaign("titleCampaign", event.target.value)
            }
          />
          <p style={{ color: "red", textAlign: "center" }}>{error}</p>
          <div className={classes.wrapButton}>
            <ButtonComponent
              className={cn(classes.buttonChange, "btn--rounder")}
              title={t("common.btn.create")}
              backgroundColor='#05AE34'
              color='white'
              handleClickButton={() => handleSubmitCreateCampaign()}
            />
          </div>
        </div>
      </FormDialog>
      <FormDialog
        isOpen={showModalAllTags}
        handleClose={() => handleCloseShowModalAllTagsPopup()}
      >
        <div style={{ padding: "10px" }}>
          <h4 className={classes.title}>
            {t("customer_management.tags.list_title")}
          </h4>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {(selectedModalTags || []).map((item, idx) => {
              let obj = _.find(
                (fanpageSelected && fanpageSelected.bombotTags) || [],
                function (obj) {
                  return obj.id === item;
                }
              );
              if (!_.isEmpty(obj)) {
                return (
                  <div key={idx} style={{ width: "50%", padding: "10px" }}>
                    <span
                      className={classes.classTags}
                      style={{
                        background: `#${obj.color}`,
                        color: obj.color === "000000" ? "white" : "",
                        fontSize: "0.8125rem",
                        marginBottom: 3,
                        borderRadius: 16,
                        padding: "2px 5px",
                        display: "block",
                        boxSizing: "border-box",
                        width: "100%",
                      }}
                    >
                      {obj.name}
                    </span>
                  </div>
                );
              }
            })}
            
          </div>
        </div>
      </FormDialog>
      {isSearch && (
        <div className={classes.wrapperSearch}>
          <Flex align='center' justify='space-between' className='pr-4'>
            <Flex align='center' auto>
              <TextField
                variant='outlined'
                size='small'
                onChange={(event) => handleSearch("search", event.target.value)}
                placeholder={t("customer_management.search")}
                style={{ maxWidth: 500, width: "100%" }}
                id='input-with-icon-textfield'
                className='bg-white'
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Flex>
            <Flex align='center' className='ml-3' flexWrap='wrap'>
              <ButtonComponent
                id='filter_customer'
                className='btn--rounder btn--primary mr-2'
                title={
                  <Flex align='center'>
                    <FilterListIcon />
                    <p className='ml-2'>{t("common.btn.filter_customer")}</p>
                  </Flex>
                }
                backgroundColor='#05AE34'
                color='white'
                disabled={_.isEmpty(fanpageSelected) ? true : false}
                handleClickButton={() => handleSubmitFilter()}
              />
              <ButtonComponent
                className='btn--rounder btn--primary'
                title={
                  <Flex align='center'>
                    <SettingsIcon />
                    <p className='ml-2'>{t("common.btn.update_info")}</p>
                  </Flex>
                }
                backgroundColor='#05AE34'
                color='white'
                disabled={_.isEmpty(fanpageSelected) ? true : false}
                handleClickButton={handleClick}
              />
            </Flex>
          </Flex>
        </div>
      )}

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <MenuItem onClick={() => openModalTag("add")}>
          {t("customer_management.add_bombot_tag.title")}
        </MenuItem>
        <MenuItem onClick={() => openModalTag("edit")}>
          {t("customer_management.remove_bombot_tag.title")}
        </MenuItem>
      </Menu>

      {rows.length > 0 && (
        <>
          <h4 className='mt-3'>
            {t("customer_management.list.amount_customer")}:{" "}
            <span style={{ color: "red" }}>
              {(!isCheckedAllPage && isUseCheckedAllPage) ? 0 : (totalNumber <= 0 ? 0 : totalNumber)}
            </span>
            <span style={{ color: "#05AE34" }}>
              /{parseInt(data && data.totalElements)}
            </span>
          </h4>
          {/* <div style={{ display: "flex", alignItems: "center" }}>
            <Checkbox
              className={classes.iconCheck}
              checked={
                isCheckedAllPage && isUseCheckedAllPage
              }
              onChange={() => handleCheckedAllPage()}
            // inputProps={{ "aria-labelledby": labelId }}
            />
            <span>{t("customer_management.list.checked_all")}</span>
          </div> */}
        </>
      )}
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <TableContainer>
            <Table
              className={classes.table}
              aria-labelledby='tableTitle'
              size={dense ? "small" : "medium"}
              aria-label='enhanced table'
            >
              <EnhancedTableHead
                classes={classes}
                numSelected={selected.length}
                onSelectAllClick={handleSelectAllClick}
                rowCount={rows.length}
                checkBoxSelectAll={checkBoxSelectAll}
                isEmptyCheckItem={isEmptyCheckItem}
              />
              <TableBody>
                {(rows || []).map((row, index) => {
                  const isItemSelected = isSelected(row.name);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow role='checkbox' tabIndex={-1} key={index}>
                      <TableCell>
                        <Checkbox
                          className={classes.iconCheck}
                          checked={row.checked}
                          inputProps={{ "aria-labelledby": labelId }}
                          onChange={() => handleSelectCheckBox(row)}
                        />
                      </TableCell>
                      <TableCell align='right'>{row.uid}</TableCell>
                      <TableCell align='right'>{row.name}</TableCell>
                      <TableCell align='right'>
                        {_.upperCase(row.gender) ==
                          _.upperCase(types.GENDER.MALE)
                          ? t("update.gender.male")
                          : _.upperCase(row.gender) ==
                            _.upperCase(types.GENDER.FEMALE)
                            ? t("update.gender.female")
                            : t("update.gender.other")}
                      </TableCell>
                      <TableCell align='right'>
                        {row.lastInboxTime ? moment(row.lastInboxTime).format("DD/MM/YYYY HH:mm") : ''}
                      </TableCell>
                      <TableCell align='right'>{row?.phone}</TableCell>
                      <TableCell align='right'>
                        {(row.fbTags || []).map((item, idx) => {
                          let obj = _.find(
                            (fanpageSelected && fanpageSelected.fbTags) || [],
                            function (obj) {
                              return obj.id == item;
                            }
                          );
                          if (!_.isEmpty(obj)) {
                            return (
                              <span
                                className={classes.classTags}
                                style={{
                                  background: `#${obj.color}`,
                                  color: obj.color === "000000" ? "white" : "",
                                  fontSize: "0.8125rem",
                                  display: "block",
                                  marginBottom: 3,
                                  borderRadius: 16,
                                  padding: "2px 5px",
                                }}
                              >
                                {obj.name}
                              </span>
                            );
                          }
                        })}
                      </TableCell>
                      <TableCell align='right'>
                        {(() => {
                          // Filter the tags to include only those present in fanpageSelected.bombotTags
                          const filteredTags = (row.bombotTags || []).filter(item => 
                            _.find((fanpageSelected && fanpageSelected.bombotTags) || [], { id: item })
                          );

                          return (
                            <>
                              {filteredTags.map((item, idx) => {
                                let obj = _.find(
                                  (fanpageSelected && fanpageSelected.bombotTags) || [],
                                  function (obj) {
                                    return obj.id === item;
                                  }
                                );
                                if (!_.isEmpty(obj) && parseInt(idx) < 6) {
                                  return (
                                    <span
                                      key={item} // It's important to add a unique key for each element
                                      className={classes.classTags}
                                      style={{
                                        background: `#${obj.color}`,
                                        color: obj.color === "000000" ? "white" : "",
                                        fontSize: "0.8125rem",
                                        display: "block",
                                        marginBottom: 3,
                                        borderRadius: 16,
                                        padding: "2px 5px",
                                      }}
                                    >
                                      {obj.name}
                                    </span>
                                  );
                                }
                              })}
                              {filteredTags.length > 5 && !showModalAllTags && (
                                <button onClick={() => handleShowMoreTags(filteredTags)} className={classes.buttonLoadMore}>
                                  Xem tất cả <b>({filteredTags.length}) tags</b>
                                </button>
                              )}
                            </>
                          );
                        })()}
                      </TableCell>
  
                      <TableCell align='right'>
                        {moment(row.updatedAt).format("DD/MM/YYYY HH:mm")}
                      </TableCell>
                      <TableCell>
                        <Flex align='center' justify='center'>
                          <span
                            className={classes.icon}
                            onClick={() => handleDetail(row)}
                          >
                            <ErrorOutlineIcon />
                          </span>
                          <span
                            className={cn(classes.icon, "ml-2")}
                            onClick={() => handleEdit(row)}
                          >
                            <EditIcon />
                          </span>
                        </Flex>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          {rows.length == 0 && (
            <p className='py-4 text-muted text-center'>{t("update.no_data")}</p>
          )}
          {isPagination && rows.length > 0 && (
            <Flex
              className='w-100 px-3 pb-3'
              align='center'
              justify='space-between'
            >
              <TablePagination
                rowsPerPageOptions={[types.PAGINATION.SIZE, 20, 30, 50]}
                component='div'
                count={parseInt(data && data.totalElements)}
                rowsPerPage={rowsPerPage}
                page={page}
                labelRowsPerPage={t("update.display")}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                className={"pagination"}
              />
              <Pagination
                count={Math.ceil((data && data.totalElements) / rowsPerPage)}
                page={page == 0 ? page + 1 : page}
                onChange={handleChange}
              />
            </Flex>
          )}
        </Paper>
        {/* {rows.length > 0 && (
          <FormControlLabel
            control={<Switch checked={dense} onChange={handleChangeDense} />}
            label="Thay đổi khoảng cách hiển thị gần"
          />
        )} */}

        <div className={classes.buttonIEA}>
          <ButtonComponent
            id='import_customer'
            className={cn(classes.buttonImport, "btn--rounder", "btn--primary")}
            title={
              <Flex align='center'>
                <ExitToAppIcon />
                <span className='ml-1'>{t("common.btn.import_customer")}</span>
              </Flex>
            }
            backgroundColor='#0c0b0b'
            color='white'
            handleClickButton={() => handleSubmitImport()}
            disabled={_.isEmpty(fanpageSelected) ? true : false}
          />
          <ButtonComponent
            id='export_customer'
            className={cn(classes.buttonExport, "btn--rounder", "btn--primary")}
            title={
              <Flex align='center'>
                <GetAppIcon />
                <span className='ml-1'>{t("common.btn.export_customer")}</span>
              </Flex>
            }
            backgroundColor='#FF8000'
            color='white'
            handleClickButton={() => handleSubmitExport()}
            disabled={_.isEmpty(fanpageSelected) ? true : false}
          />
          {/* <ButtonComponent
            className={classes.buttonAdd}
            title={"Tạo chiến dịch"}
            backgroundColor="#05AE34"
            color="white"
            handleClickButton={() => handleOpenExtention()}
            disabled={_.isEmpty(fanpageSelected) ? true : false}
          /> */}
          <ButtonComponent
            id='create_campaign'
            className={cn(
              classes.buttonAdd,
              "btn--rounder",
              "btn--primary--filled"
            )}
            title={
              <Flex align='center'>
                <AddCircleIcon />
                <span className='ml-1'>
                  {t("customer_management.create_campaign.title")}
                </span>
              </Flex>
            }
            backgroundColor='#05AE34'
            color='white'
            handleClickButton={() => handleSubmitCreate()}
            disabled={_.isEmpty(fanpageSelected) ? true : false}
          />
          {typeModalTag && (
            <ModalTagsBombot
              isRemove={typeModalTag === "edit"}
              handleClose={onCloseModal}
              setMessageType={setTypeMessage}
              customerAfterFilter={customerAfterFilter}
              customerAll={customerAll}
            />
          )}
          <FilterCustomerModal
            customerAll={customerAll}
            isOpen={isOpenModalFilter}
            handleClose={toggleModalFilter}
            onSubmitFilter={onSubmitFilter}
          />
        </div>
      </div>
    </>
  );
}

EnhancedTable.propTypes = {
  data: PropTypes.array,
  search: PropTypes.string,
  isSearch: PropTypes.bool,
  isPagination: PropTypes.bool,
  getData: PropTypes.func,
  handleGetData: PropTypes.func,
};

EnhancedTable.defaultProps = {
  isPagination: true,
  isSearch: true,
  handleGetData: (f) => f,
  getData: (f) => f,
  data: [],
  search: null,
};
