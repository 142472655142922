import LOGO from './logo.png';
import LOGO_MINI from './logo-mini.png';
import LOADING from './loading.svg';
import FAVICON from './favicon.ico';
import GIFT_BOX from './gift-box.png';
import BACKGROUND from "./background.png";
import BACKGROUND_INTE from "./background-inte.png";
import BOXMAIL from './boxmail.svg';
import CALL from './call.svg';
import CHAT from './chat.svg';
import CLOSE from './close.svg';
import WEV from './web.svg';
import FB from './messenger.svg';

export default {
    BACKGROUND,
    BACKGROUND_INTE,
    LOGO,
    LOADING,
    FAVICON,
    LOGO_MINI,
    GIFT_BOX,
    BOXMAIL,
    CALL,
    CHAT,
    CLOSE,
    WEV,
    FB
};
