import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import _ from "lodash";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import moment from "moment";
import types from "../constants/types";
import { useTranslation } from "react-i18next";
const headCells = [
  {
    id: "time",
    numeric: true,
    disablePadding: true,
    label: "account_management.payment_history.time",
  },
  {
    id: "code",
    numeric: true,
    disablePadding: true,
    label: "account_management.payment_history.order_code",
  },
  {
    id: "product",
    numeric: true,
    disablePadding: true,
    label: "account_management.payment_history.package",
  },
  {
    id: "totalPrice",
    numeric: true,
    disablePadding: true,
    label: "account_management.payment_history.total",
  },
  {
    id: "pay",
    numeric: true,
    disablePadding: true,
    label: "account_management.payment_history.method",
  },
  {
    id: "status",
    numeric: true,
    disablePadding: true,
    label: "account_management.payment_history.status",
  },
];

function EnhancedTableHead(props) {
  const { t } = useTranslation();
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
          >
            <TableSortLabel hideSortIcon={true}>
              {t(headCell.label)}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: "15px 15px 15px 0px",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: "100%",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  noData: {
    color: "rgba(0,0,0,.5)",
    fontWeight: "bold",
    textAlign: "center",
    paddingBottom: 15,
  },
}));

export default function ListHistoryjs(props) {
  const { data } = props;
  const rows = (data && data.history) || [];
  const classes = useStyles();
  const [dense, setDense] = React.useState(true);
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            aria-label="enhanced table"
          >
            <EnhancedTableHead classes={classes} />
            <TableBody>
              {(rows || []).map((row, index) => {
                return (
                  <TableRow role="checkbox" tabIndex={-1} key={index}>
                    <TableCell align="right">
                      {moment(row.updatedAt).format("DD/MM/YYYY HH:mm")}
                    </TableCell>
                    <TableCell align="right">{row.id}</TableCell>
                    <TableCell align="right">{row.name}</TableCell>
                    <TableCell align="right">{row.amount}</TableCell>
                    <TableCell align="right">{row.method}</TableCell>
                    <TableCell align="right">
                      {_.upperCase(row.status) == _.upperCase(types.STATUS.DONE)
                        ? "Hoàn thành"
                        : _.upperCase(row.status) ==
                          _.upperCase(types.STATUS.PENDING)
                        ? "Chưa chạy"
                        : "Đang chạy"}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        {rows.length == 0 && (
          <p className="py-4 text-muted text-center">{t("update.no_data")}</p>
        )}
        {/* <TablePagination
          rowsPerPageOptions={[5, 10, 20, 50]}
          component="div"
          count={}
          rowsPerPage={rowsPerPage}
          page={page}
          labelRowsPerPage={"Hiển thị"}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          className={"pagination"}
        /> */}
      </Paper>
      {/* {rows.length > 0 && (
        <FormControlLabel
          control={<Switch checked={dense} onChange={handleChangeDense} />}
          label="Thay đổi khoảng cách hiển thị gần"
        />
      )} */}
    </div>
  );
}
