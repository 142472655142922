import {
  Button,
  Chip,
  CircularProgress,
  DialogContent,
  IconButton,
  Snackbar,
} from "@material-ui/core";
import { Dialog, DialogTitle } from "@material-ui/core";
import React, { useMemo, useState } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import Flex from "components/Flex";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import Tooltip from "@material-ui/core/Tooltip";
import AddTags from "./AddTags";
import {
  customerCheck,
  selectBombotTags,
  selectFanpageSelected,
  selectIsShowModal,
} from "views/Customer/CustomerSelectors";
import customerApi from "api/customerApi";
import { useHistory } from "react-router-dom";
import { toggleModalAdd } from "views/Customer/CustomerReducer";
import { useTranslation } from "react-i18next";

const ModalTagsBombot = ({
  handleClose,
  open = true,
  isRemove,
  setMessageType,
  customerAfterFilter,
  customerAll
}) => {
  const fanpage_selected = useSelector(selectFanpageSelected);
  const bombotTags = useSelector(selectBombotTags);
  const dispatch = useDispatch();
  const [tagSelected, setTagSelected] = useState([]);
  const showModalCreate = useSelector(selectIsShowModal);
  const customerExclude = useSelector(customerCheck);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const options = useMemo(() => {
    const tagSelectedIds = tagSelected.map((t) => t.id);
    return bombotTags.filter((tag) => !tagSelectedIds.includes(tag.id));
  }, [bombotTags, tagSelected]);

  const onChange = (_, tags) => {
    setTagSelected(tags);
  };

  const onSave = async () => {
    setIsLoading(true);
    setMessageType(null);
    const searchParam = new URLSearchParams(window.location.search);
    const getQuery = searchParam.get("query");
    let payload = {}
    let customerDifferent = customerAll.filter(function (customer) {
      // Kiểm tra xem customer có trong customerAfterFilter hay không
      let existsInFilter = customerAfterFilter.some(function (filteredCustomer) {
        return filteredCustomer.uid === customer.uid;
      });
      // Trả về true nếu không tồn tại trong customerAfterFilter
      return !existsInFilter;
    });
    if (customerAfterFilter.length) {
      payload = {
        search: getQuery || `pageId==${fanpage_selected.pageId}`,
        exclude: customerDifferent.map((cus) => cus.uid),
        bombotTags: tagSelected.map((t) => t.id),
      };

    } else {
      payload = {
        search: getQuery || `pageId==${fanpage_selected.pageId}`,
        exclude: customerExclude.map((cus) => cus.uid),
        bombotTags: tagSelected.map((t) => t.id),
      };
    }

    try {
      let res;
      if (isRemove) {
        res = await customerApi.removeMultiCustomersBombotTags(payload);
      } else {
        res = await customerApi.addMultiCustomersBombotTags(payload);
      }
      if (res === true) {
        setMessageType("success");
        handleClose();
      } else {
        setMessageType("error");
      }
    } catch (error) {
      setMessageType("error");
    } finally {
      setIsLoading(false);
      window.location.reload();
    }
  };

  const toggleModalCreate = () => {
    dispatch(toggleModalAdd());
  };

  const { t } = useTranslation();

  return (
    <div>
      <Dialog onClose={handleClose} open={open} maxWidth="sm" fullWidth={true}>
        <DialogTitle>
          {isRemove
            ? t("customer_management.remove_bombot_tag.title")
            : t("customer_management.add_bombot_tag.title")}
        </DialogTitle>

        <DialogContent>
          {!isRemove && (
            <div className="mb-2">
              {t("customer_management.add_bombot_tag.description")}
            </div>
          )}
          <Flex align="center" className="mb-2">
            <Autocomplete
              multiple
              className="w-100 m-0"
              size="small"
              value={tagSelected}
              options={options}
              getOptionLabel={(option) => option?.name || ""}
              onChange={onChange}
              selectOnFocus
              clearOnBlur
              renderOption={(value) => {
                return (
                  <p
                    className="p-2"
                    onClick={(e) => {
                      if (value.onClick) {
                        value.onClick();
                      }
                    }}
                  >
                    {value.name}
                  </p>
                );
              }}
              renderTags={(value, getTagProps) => {
                return value.map((option, index) => (
                  <Chip
                    variant="outlined"
                    label={option.name}
                    {...getTagProps({ index })}
                    style={{ backgroundColor: `#${option?.color}` }}
                  />
                ));
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder={t("customer_management.add_bombot_tag.choose")}
                />
              )}
            />
            {!isRemove && (
              <Tooltip
                title={t("customer_management.add_bombot_tag.add_new_tag")}
                placement="top"
                className="ml-2"
              >
                <IconButton onClick={toggleModalCreate}>
                  <AddCircleOutlineIcon />
                </IconButton>
              </Tooltip>
            )}
          </Flex>
          <Flex align="center" justify="flex-end" className="mt-3">
            <Button
              onClick={handleClose}
              size="small"
              className="btn--rounder btn--primary"
            >
              {t("common.btn.cancel")}
            </Button>

            <Button
              size="small"
              disabled={isLoading || !tagSelected.length}
              onClick={onSave}
              className="btn--rounder btn--primary--filled ml-2"
            >
              <Flex align="center">
                {isLoading && <CircularProgress size={12} color="white" />}
                <p className="my-0 ml-2"> {t("common.btn.save")}</p>
              </Flex>
            </Button>
          </Flex>
        </DialogContent>
        <AddTags
          open={showModalCreate}
          handleClose={toggleModalCreate}
          fanpage_selected={fanpage_selected}
        />
      </Dialog>
    </div>
  );
};

export default ModalTagsBombot;
