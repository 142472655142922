import React from "react";
import ReactDOM from "react-dom";
import Root from "./Root";
import store from "./configs/redux/store";
import * as serviceWorker from "./serviceWorker";
import { initFacebookSdk } from "./configs/init-facebook-sdk";
import "./assets/styles/common.scss";
import "./assets/styles/normalize.css";
initFacebookSdk();
ReactDOM.render(<Root store={store} />, document.getElementById("root"));

serviceWorker.unregister();
