import { createSlice } from "@reduxjs/toolkit";
import initialState from "../../configs/redux/initialState";

const loginReducer = createSlice({
  name: "login",
  initialState: initialState.loginReducer,
  reducers: {
    setAccount: (state, action) => {
      state.account = action.payload;
    },
    setStatusLogined: (state, action) => {
      state.statusLogined = action.payload;
    },
    setForgotPassword: (state, action) => {
      state.forgotPassword = action.payload;
    },
    
  }
});

export const {
    setAccount,
    setStatusLogined,
    setForgotPassword
} = loginReducer.actions;

export default loginReducer.reducer;
