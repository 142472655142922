import axiosClient from "./axiosClient";
import urlApi from "./url";
const appApi = {
  getUser: (uid) => {
    return axiosClient.get(`${urlApi.user.user}/${uid}`);
  },
  forgotPassword: (params) => {
    return axiosClient.get(`${urlApi.user.forgotPassword}`, {params});
  },
  
};

export default appApi;
