import { createSlice } from "@reduxjs/toolkit";
import initialState from "../../configs/redux/initialState";

const fanpageReducer = createSlice({
  name: "fanpage",
  initialState: {
    ...initialState.fanpageReducer,
  },
  reducers: {
    setFanpage: (state, action) => {
      state.fanpage = action.payload;
    },
    setFanpageFull: (state, action) => {
      state.fanpageFull = action.payload;
    },
    setFanpageSelected: (state, action) => {
      state.loading_page_detail = false;
      state.fanpage_selected = action.payload;
    },
  },
});

export const { setFanpage, setFanpageFull, setFanpageSelected } =
  fanpageReducer.actions;

export default fanpageReducer.reducer;
