import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import MainLayoutView from "./MainLayoutView";
import * as selectorsSidebar from "../Sidebar/SidebarSelectors";
const mapStateToProps = state => {
  return {
    dehaze: selectorsSidebar.dehaze(state),
  };
};

const mapDispatchToProps = dispatch => ({
  
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MainLayoutView));
