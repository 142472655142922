import { combineReducers } from "@reduxjs/toolkit";
import { connectRouter } from "connected-react-router";
import { reducer as toastrReducer } from "react-redux-toastr";
import { history } from "../../utils/history";
import homeReducer from "../../views/Home/HomeReducer";
import loginReducer from "../../views/Login/LoginReducer";
import sidebarReducer from "../../views/Sidebar/SidebarReducer";
import campaignReducer from "../../views/Campaign/CampaignReducer";
import appReducer from "../../views/App/AppReducer";
import fanpageReducer from "../../views/Fanpage/FanpageReducer";
import settingAccountReducer from "../../views/SettingAccount/SettingAccountReducer";
import dialogPolicyPointReducer from "../../views/DialogPolicyPoint/DialogPolicyPointReducer";
import customerReducer from "../../views/Customer/CustomerReducer";
import inviteReducer from "../../views/Invite/InviteReducer";
import { configSlice } from "../../stores/config/config.slice";
import alertSlice from "stores/alert/slice.alert";

const rootReducer = combineReducers({
  router: connectRouter(history),
  toastr: toastrReducer,
  homeReducer,
  loginReducer,
  sidebarReducer,
  campaignReducer,
  appReducer,
  fanpageReducer,
  settingAccountReducer,
  dialogPolicyPointReducer,
  customerReducer,
  inviteReducer,
  [configSlice.name]: configSlice.reducer,
  [alertSlice.name]: alertSlice.reducer,
});

export default rootReducer;
