import fanpageApi from "../../api/fanpageApi";
import { setLoading } from "../App/AppReducer";
import {
  setFanpageSelected,
  setFanpage,
  setFanpageFull,
  startAddTags,
  addTagsSuccess,
  addTagsFailed,
} from "./FanpageReducer";

export function getFanpage(params) {
  return (dispatch) => {
    dispatch(setLoading(true));
    return fanpageApi.get(params).then(
      (res) => {
        dispatch(setFanpage(res));
        dispatch(setLoading(false));
      },
      (error) => {
        dispatch(setLoading(false));
      }
    );
  };
}

export function getFanpageFull(params) {
  return (dispatch) => {
    dispatch(setLoading(true));
    return fanpageApi.get(params).then(
      (res) => {
        dispatch(setFanpageFull(res));
        dispatch(setLoading(false));
      },
      (error) => {
        dispatch(setLoading(false));
      }
    );
  };
}

export function getFanpageDetail(id) {
  return (dispatch) => {
    dispatch(setLoading(true));
    return fanpageApi.getDetailFanpage(id).then(
      (res) => {
        dispatch(setFanpageSelected(res));
        dispatch(setLoading(false));
      },
      (error) => {
        dispatch(setLoading(false));
      }
    );
  };
}
