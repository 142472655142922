import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import HeaderView from "./HeaderView";
import * as selectorsSidebar from "../Sidebar/SidebarSelectors";
import * as actionsSidebar from "../Sidebar/SidebarActions";
import * as actionsLogin from "../Login/LoginActions";
import * as selectorsApp from "../App/AppSelectors";
import * as selectorsHome from "../Home/HomeSelectors";
const mapStateToProps = state => {
  return {
    getMenu: selectorsSidebar.getMenu(state),
    user: selectorsApp.user(state),
    share: selectorsHome.share(state),
    dehaze: selectorsSidebar.dehaze(state),
  };
};

const mapDispatchToProps = dispatch => ({
  onMenu: (data) => dispatch(actionsSidebar.menu(data)),
  onLogout: () => dispatch(actionsLogin.onLogout()),
  
  
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HeaderView));
