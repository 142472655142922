import React from "react";
import { Controller } from "react-hook-form";
import TextField from "@material-ui/core/TextField";
import {
  DateRangeDelimiter,
  LocalizationProvider,
  DesktopDateRangePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@material-ui/pickers/adapter/date-fns";
import Flex from "components/Flex";
import { useTranslation } from "react-i18next";

const BaseDatePicker = ({ control, name, defaultValue, rules, ...rest }) => {
  const { t } = useTranslation();
  return (
    <Flex align="center">
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        rules={rules}
        render={({ field }) => {
          return (
            <LocalizationProvider dateAdapter={DateFnsUtils}>
              <DesktopDateRangePicker
                {...field}
                startText={t("update.form.plh_select")}
                endText={t("update.form.plh_select")}
                maxDate={new Date()}
                inputFormat="dd/MM/yyyy"
                onChange={(date) => {
                  field.onChange(date);
                }}
                value={field.value || [null, null]}
                {...rest}
                renderInput={(startProps, endProps) => {
                  return (
                    <>
                      <TextField
                        size="small"
                        className="input_picker"
                        {...startProps}
                        error={null}
                        helperText={null}
                      />
                      <DateRangeDelimiter>
                        {t("update.form.to")}
                      </DateRangeDelimiter>
                      <TextField
                        size="small"
                        className="input_picker"
                        {...endProps}
                        error={null}
                        helperText={null}
                      />
                    </>
                  );
                }}
              />
            </LocalizationProvider>
          );
        }}
      />
    </Flex>
  );
};

export default BaseDatePicker;
