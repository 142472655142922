import axiosClient from "./axiosClient";
import urlApi from "./url";

const getGuide = (params) => {
  return axiosClient.get(`${urlApi.appConfig.api}/guide_webapp`, { params });
};

const getCountry = (params) => {
  return axiosClient.get(`${urlApi.appConfig.api}/country`, { params });
};

const recordLog = (payload) => {
  return axiosClient.post(urlApi.appConfig.addLog, payload);
};
const AppConfigAPI = {
  getGuide,
  recordLog,
  getCountry,
};

export default AppConfigAPI;
