import axiosClient from "./axiosClient";
import urlApi from "./url";
const inviteApi = {
  confirmInvite: (code) => {
    return axiosClient.get(`${urlApi.user.confirmInviteCode}/${code}`);
  },
  onUnlink: (params) => {
    return axiosClient.delete(`${urlApi.user.unlink}/${params.id}`);
  },
};

export default inviteApi;
