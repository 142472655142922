import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

const BaseAutocomplete = ({
  errors,
  name,
  options,
  defaultValue,
  control,
  inputStyle = {},
  ...props
}) => {
  const { t } = useTranslation();
  return (
    <Controller
      name={name}
      control={control}
      color="secondary"
      defaultValue={defaultValue}
      render={({ field }) => {
        return (
          <Autocomplete
            {...props}
            {...field}
            className="m-0 w-100"
            multiple
            onChange={(_, tags) => field.onChange(tags)}
            options={options}
            getOptionLabel={(option) => option?.label || ""}
            selectOnFocus
            clearOnBlur
            renderOption={(value) => {
              return <p className="p-2">{value.label}</p>;
            }}
            value={field.value || []}
            renderInput={(params) => (
              <TextField
                {...params}
                style={{ ...inputStyle }}
                size="small"
                variant="outlined"
                placeholder={t("update.form.plh_select")}
              />
            )}
          />
        );
      }}
    />
  );
};

export default BaseAutocomplete;
