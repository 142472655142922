import { createSlice } from "@reduxjs/toolkit";
import initialState from "../../configs/redux/initialState";

const campaignReducer = createSlice({
  name: "campaign",
  initialState: initialState.campaignReducer,
  reducers: {
    setCampaign: (state, action) => {
      state.campaign = action.payload;
    },
   
  }
});

export const {
    setCampaign
} = campaignReducer.actions;

export default campaignReducer.reducer;
