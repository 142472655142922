import whatYouNeedApi from "../../api/whatYouNeedApi";
import inviteApi from "../../api/inviteApi";
import { accountApi } from "../../api/accountApi";
import {
  whatYouNeed,
  changePassword,
  changePasswordStatus,
  setInviteCode,
  setUnlink,
  setMessageUnlink,
  setMessageInviteError
} from "./SettingAccountReducer";

import _ from "lodash";

export function getWhatYouNeed(data) {
  return (dispatch) => {
    return whatYouNeedApi.whatYouNeed(data).then(
      (res) => {
        dispatch(whatYouNeed(res));
      },
      (error) => {
        throw error;
      }
    );
  };
}

export function onUnlink(data) {
  return (dispatch) => {
    return inviteApi.onUnlink(data).then(
      (res) => {
        if (res) {
          dispatch(setUnlink(true));
        } else {
          dispatch(setUnlink(false));
        }
      },
      (error) => {
        dispatch(setMessageUnlink(error.response.data.error));
        dispatch(setUnlink(false));
      }
    );
  };
}

export function onSubmitChangePassword(params) {
  return (dispatch) => {
    return accountApi.changePassword(params).then(
      (res) => {
        if (_.isObject(res)) {
          dispatch(changePassword(false));
        } else {
          dispatch(changePassword(true));
        }
      },
      () => {
        throw dispatch(changePassword(false));
      }
    );
  };
}

export function onGetInviteCode(params) {
  return (dispatch) => {
    return accountApi.getInviteCode(params).then(
      (res) => {
        if (res.code) {
          dispatch(setInviteCode(res));
        }
      },
      (error) => {
        console.log("error", error.response.data.error);
        dispatch(setMessageInviteError(error.response.data.error));
      }
    );
  };
}

export function onChangePasswordStatus(data) {
  return (dispatch) => {
    dispatch(changePasswordStatus(data));
  };
}
