import React from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import cn from "classnames";
import IMAGES from "../images";
const useStyles = makeStyles((theme) => ({
  wrapperLoading: {
    width: "100%",
    height: "100%",
    position: 'fixed',
    left: '0px',
    top: '0px',
    background: 'rgba(0,0,0,.2)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: '9999',
  },
}));

export default function Loading(props) {
  const classes = useStyles();

  return (
    <div className={classes.wrapperLoading}>
      <img src={IMAGES.LOADING} />
    </div>
  );
}

Loading.propTypes = {};

Loading.defaultProps = {};
