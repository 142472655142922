import whatYouNeedApi from "../../api/whatYouNeedApi";
import {
  whatYouNeed,
  redeemPoints
} from "./DialogPolicyPointReducer";
import _ from "lodash";

export function getWhatYouNeed(data) {
  return (dispatch) => {
    return whatYouNeedApi.whatYouNeed(data).then(
      (res) => {
        dispatch(whatYouNeed(res));
      },
      (error) => {
        throw error;
      }
    );
  };
}

export function getRedeemPoints(data) {
  return (dispatch) => {
    return whatYouNeedApi.redeemPoints(data).then(
      (res) => {
        dispatch(redeemPoints(res));
      },
      (error) => {
        dispatch(redeemPoints(error));
        //throw error;
      }
    );
  };
}