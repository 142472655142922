import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import InvitePageView from "./InviteView";
import * as actionsInvite from "./InviteActions";
import * as selectorsApp from "../App/AppSelectors";
import * as actionsApp from "../App/AppActions";
import * as actionsLogin from "../Login/LoginActions";
import * as selectorsInvite from "./InviteSelectors";
const mapStateToProps = state => {
  return {
    user: selectorsApp.user(state),
    statusConfirmInviteCode: selectorsInvite.confirmInviteCode(state),
    loading: selectorsApp.loading(state),
  };
};

const mapDispatchToProps = dispatch => ({
  login: () => dispatch(actionsLogin.login()),
  confirmInviteCode: (params) => dispatch(actionsInvite.confirmInviteCode(params)),
  onGetUser: (uid) => dispatch(actionsApp.onGetUser(uid)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(InvitePageView));
