export default {
  STATUS: {
    PENDING: "pending",
    RUNNING: "running",
    DONE: "done",
  },
  PAGINATION: {
    PAGE: 0,
    SIZE: 10,
  },
  URL: {
    TUTORIAL: "https://hotro.bombot.vn/",
    FACEBOOK: "https://www.facebook.com/?ref=bombot",
    EXTENSION: "https://bombotsa.blob.core.windows.net/extensions/bombot-extension.zip?v=2.6.1&time=20",
  },
  PACKAGE: {
    TRIAL: "trial",
  },
  ACTIONS: {
    DETAIL: "DETAIL",
    EDIT: "EDIT",
    FILTER: "FILTER",
  },
  OTP: {
    SEC: "60",
  },
  GENDER: {
    MALE: "MALE",
    FEMALE: "FEMALE",
  },
  MENU: {
    HOME: {
      NAME: "menu.overall",
      LINK: "/trang-chu",
    },
    CUSTOMER: {
      NAME: "menu.customer",
      LINK: "/quan-ly-khach-hang",
    },
    FANPAGE: {
      NAME: "menu.fanpage",
      LINK: "/quan-ly-fan-page",
    },
    CAMPAGIGN: {
      NAME: "menu.campaign",
      LINK: "/quan-ly-chien-dich",
    },
    SETTING_ACCOUNT: {
      NAME: "menu.account",
      LINK: "/quan-ly-tai-khoan",
    },
  },
};
