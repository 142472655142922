import { accountApi } from "../../api/accountApi";
import appApi from "../../api/appApi";
import _ from "lodash";
import {
  setAccount,
  setStatusLogined,
  setForgotPassword,
} from "./LoginReducer";
import { setUser, setLoading } from "../App/AppReducer";

export function login(ref) {
  return (dispatch) => {
    dispatch(setLoading(true));
    return accountApi.login(ref).then(
      (res) => {
        localStorage.setItem("token", res.token);
        localStorage.setItem("id", res.id);
        dispatch(setAccount(res));
        dispatch(setUser(res));
        return appApi.getUser(res.id).then(
          (res) => {
            dispatch(setUser(res));
            dispatch(setLoading(false));
          },
          (error) => {
            dispatch(setStatusLogined(false));
            dispatch(setLoading(false));
          }
        );
      },
      (error) => {
        dispatch(setLoading(false));
      }
    );
  };
}

export function onSubmitEmail(params) {
  return (dispatch) => {
    dispatch(setLoading(true));
    return accountApi.onSubmitEmail(params).then(
      (res) => {
        if (_.isObject(res) && res.id) {
          localStorage.setItem("token", res.token);
          localStorage.setItem("id", res.id);
          dispatch(setAccount(res));
          dispatch(setUser(res));
        } else {
          dispatch(setStatusLogined(false));
        }
        dispatch(setLoading(false));
      },
      (error) => {
        dispatch(setStatusLogined(false));
        dispatch(setLoading(false));
      }
    );
  };
}

export function onLogout() {
  return (dispatch) => {
    return accountApi.logout();
  };
}

export function onSubmitForgotPassword(params) {
  return (dispatch) => {
    dispatch(setLoading(true));
    return appApi.forgotPassword(params).then(
      (res) => {
        if (res && !_.isObject(res)) {
          dispatch(setForgotPassword(true));
        } else {
          dispatch(setForgotPassword(false));
        }
        dispatch(setLoading(false));
      },
      (error) => {
        dispatch(setForgotPassword(false));
        dispatch(setLoading(false));
      }
    );
  };
}
