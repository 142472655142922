import React, { Suspense, useEffect, useState } from "react";
import { Route, Switch, Redirect, useHistory } from "react-router-dom";
import _ from "lodash";
import { pathKeys } from "../../constants";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { connect, useDispatch, useSelector } from "react-redux";
import Home from "../Home";
import Login from "../Login";
import Fanpage from "../Fanpage";
import Customer from "../Customer";
import Campaign from "../Campaign";
import SettingAccount from "../SettingAccount";
import Invite from "../Invite";
import { getCountries, getGuide } from "stores/config/config.slice";
import { isGetGuideData } from "utils/helper";
import firebase from "firebase";
import AlertProvider from "hook/AlertProvider";
import { FirebaseConfig } from "constants/firebase";
import "utils/i18n";
import { onWhatYouNeed } from "./AppActions";
import i18n from "i18next";
import { selectUser } from "./AppSelectors";
import { updateUser } from "views/Home/HomeActions";
import { useTranslation } from "react-i18next";

if (!firebase.apps.length) {
  firebase.initializeApp(FirebaseConfig);
}
firebase.analytics();

const styles = (theme) => ({});

const App = (props) => {
  const { onGetUser, user } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const userApp = useSelector(selectUser);
  useEffect(() => {
    const uid = localStorage.getItem("id");
    if (uid) dispatch(onWhatYouNeed());
    // localStorage.setItem("id", `2978907629003644`);
    // localStorage.setItem(
    //   "token",
    //   `eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIyOTc4OTA3NjI5MDAzNjQ0IiwiaXNzIjoiYm9tQm90IiwiZXhwIjoxNjMxMDQ1MjU2LCJpYXQiOjE2MzA5NTg4NTZ9.MtUpkmslH-wek_C1IKNLncepdEXscslW18OwmwpH9cQ`
    // );
  }, []);

  useEffect(() => {
    if (userApp?.id) {
      if (isGetGuideData() && i18n?.language) {
        dispatch(getGuide({ lang: i18n.language?.substring(0, 2) }));
      }
      dispatch(getCountries({ lang: i18n.language?.substring(0, 2) }));
      dispatch(
        updateUser(
          {
            lang: i18n.language?.substring(0, 2),
          },
          userApp.id
        )
      );
    }
  }, [userApp?.id]);

  useEffect(() => {
    const uid = localStorage.getItem("id");
    const searchParam = new URLSearchParams(window.location.search);
    const getLang = searchParam.get("lang");
    let search = "";
    if (/(vi|en|kh)/g.test(getLang)) {
      i18n.changeLanguage(getLang);
    }
    if (uid) {
      onGetUser(uid);
      if (getLang) {
        search = `?lang=${getLang}`;
        if (/(vi|en|kh)/g.test(getLang)) {
          dispatch(
            updateUser(
              {
                lang: getLang,
              },
              userApp.id
            )
          );
        }
      }
    } else {
      const getRef = searchParam.get("ref");
      if (getRef) search += `?ref=${getRef}`;
      if (getLang) {
        search += `${getRef ? "&" : "?"}lang=${getLang}`;
      } else {
        if (userApp?.id) {
          dispatch(
            updateUser(
              {
                lang: i18n.language?.substring(0, 2),
              },
              userApp.id
            )
          );
        }
      }
      if (getRef === "invite-code") {
        history.push({
          pathname: pathKeys.INVITE,
          search,
        });
      } else {
        history.push({
          pathname: pathKeys.LOGIN,
          search,
        });
      }
    }
  }, [onGetUser, userApp?.id]);

  useEffect(() => {
    // if (i18n.language) {
    //   console.log("cjhay den deay");
    //   const upgrade_form = document.querySelector(".b24-form-wrapper");
    //   console.log(
    //     "🚀 ~ file: AppView.js:124 ~ useEffect ~ upgrade_form",
    //     upgrade_form
    //   );
    //   var script_tag = document.createElement("script");
    //   script_tag.type = "text/javascript";
    //   script_tag.id = "upgrade_script";
    //   if (upgrade_form) {
    //     upgrade_form.remove();
    //   }
    //   switch (i18n.language?.substr(0, 2)) {
    //     case "vi": {
    //       script_tag.setAttribute("data-b24-form", "inline/50/d6om7y");
    //       script_tag.setAttribute("data-skip-moving", "true");
    //       script_tag.text = `(function (w, d, u) {
    //     var s = d.createElement('script'); s.async = true; s.src = u + '?' + (Date.now() / 180000 | 0);
    //     var h = d.getElementsByTagName('script')[0]; h.parentNode.insertBefore(s, h);
    //   })(window, document, 'https://cdn.bitrix24.vn/b10890131/crm/form/loader_50.js');`;
    //       document.querySelector("#wrapperForm").appendChild(script_tag);
    //       break;
    //     }
    //     case "en": {
    //       script_tag.setAttribute("data-b24-form", "inline/52/zwdewh");
    //       script_tag.setAttribute("data-skip-moving", "true");
    //       script_tag.text = `(function (w, d, u) {
    //     var s = d.createElement('script'); s.async = true; s.src = u + '?' + (Date.now() / 180000 | 0);
    //     var h = d.getElementsByTagName('script')[0]; h.parentNode.insertBefore(s, h);
    //   })(window, document, 'https://cdn.bitrix24.vn/b10890131/crm/form/loader_52.js');`;
    //       document.querySelector("#wrapperForm").appendChild(script_tag);
    //       break;
    //     }
    //     case "kh": {
    //       var script_tag = document.createElement("script");
    //       script_tag.id = "upgrade_script";
    //       script_tag.type = "text/javascript";
    //       script_tag.setAttribute("data-b24-form", "inline/79/4ozc5t");
    //       script_tag.setAttribute("data-skip-moving", "true");
    //       script_tag.text = `(function (w, d, u) {
    //     var s = d.createElement('script'); s.async = true; s.src = u + '?' + (Date.now() / 180000 | 0);
    //     var h = d.getElementsByTagName('script')[0]; h.parentNode.insertBefore(s, h);
    //   })(window, document, 'https://cdn.bitrix24.vn/b10890131/crm/form/loader_79.js');`;
    //       document.querySelector("#wrapperForm").appendChild(script_tag);
    //       break;
    //     }
    //     default:
    //       break;
    //   }
    // }
  }, [i18n.language]);

  useEffect(() => {
    if (document.getElementById("wrapperForm")) {
      const uid = localStorage.getItem("id");
      if (document.getElementById("wrapperUserId")) {
        document
          .getElementById("wrapperUserId")
          .append(
            parseHTML(
              `<span class='wrappeId'>${i18n.t("update.id_bombot")} <span>${
                uid || (user && user.id)
              }</span></span>`
            )
          );
      }
      const funcClose = function () {
        document.body.classList.remove("block-upgrade");
      };
      document.getElementById("overlay-upgrade").onclick = funcClose;
    }
  }, [user]);

  const parseHTML = (html) => {
    var t = document.createElement("template");
    t.innerHTML = html;
    return t.content.cloneNode(true);
  };

  // useEffect(() => {
  //   const url_string = window.location.href; //window.location.href
  //   const url = new URL(url_string);
  //   const ref = url.searchParams.get("ref");
  //   const pathname = url.pathname;
  //   // if (ref != 'invite-code' && pathname != '/invite-code') {
  //   //   // if (_.isEmpty(user)) {
  //   //     // history.push(pathKeys.HOME);
  //   //   //   history.push(pathKeys.LOGIN);
  //   //   // } else {
  //   //   //   history.push(pathKeys.HOME);
  //   //   // }
  //   // } else {
  //   //   history.push(pathKeys.INVITE);
  //   // }
  // }, [user]);

  return (
    <>
      <Suspense fallback={<div>Loading...</div>}>
        <AlertProvider>
          <Switch>
            <Redirect exact from="/" to={pathKeys.HOME} />
            <Route exact path={pathKeys.LOGIN} component={Login} />
            <Route exact path={pathKeys.HOME} component={Home} />
            <Route exact path={pathKeys.FANPAGE} component={Fanpage} />
            <Route exact path={pathKeys.CUSTOMER} component={Customer} />
            <Route exact path={pathKeys.CAMPAIGN} component={Campaign} />
            <Route exact path={pathKeys.INVITE} component={Invite} />
            <Route
              exact
              path={pathKeys.SETTING_ACCOUNT}
              component={SettingAccount}
            />
          </Switch>
        </AlertProvider>
      </Suspense>
    </>
  );
};
// }
// }

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

App.propTypes = {};

App.defaultProps = {};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(App))
);
