import {
  FormControl,
  Tooltip,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import Flex from "components/Flex";
import React, { useCallback, useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import FormDialog from "../../../../components/FormDialog";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import styles from "../../styles.module.scss";
import firebase from "firebase";
import { useDispatch, useSelector } from "react-redux";
import ButtonLoading from "components/ButtonLoading";
import { Skeleton } from "@material-ui/lab";
import { verifyNumberPhoneError } from "views/Home/HomeReducer";
import { selectErrorVerifyPhone } from "views/Home/HomeSelectors";
import homeApi from "api/homeApi";
import { setLoading } from "views/App/AppReducer";
import { showError } from "stores/alert/slice.alert";
import smsExample from "../../../../assets/images/code.jpg";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  account: {
    display: "flex",
    alignItems: "center",
    background: "#f0f0f0",
    padding: 15,
  },
  right: {
    marginLeft: 15,
  },
  wrapAccount: {
    padding: 15,
  },
  form: {
    width: "100%",
  },
  number: {
    fontWeight: "bold",
  },
  errorCode: {
    margin: "10px 0px 0px 10px",
    fontSize: "14px",
    color: "red",
    textAlign: "center",
  },
}));

let appVerifier,
  interval,
  secondRef = 60;

const DialogVerifyPhone = ({
  phone,
  handleBackVerify,
  handleVerify,
  handleVerifyOtpVoice,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isShowInputVerify, setIsShowInputVerify] = useState(false);
  const [code, setCode] = useState("");
  const [loadingVerify, setLoadingVerify] = useState(false);
  const [loadingCaptcha, setLoadingCaptcha] = useState(true);
  const [seconds, setSeconds] = useState(60);
  const [captchaArea, setCaptchaArea] = useState(null);
  const errorVerify = useSelector(selectErrorVerifyPhone);
  const [typeReceive, setTypeReceive] = useState(1);
  const { i18n, t } = useTranslation();

  const onRefChange = useCallback((node) => {
    setCaptchaArea(node);
  }, []);
  useEffect(() => {
    if (captchaArea) {
      appVerifier = new firebase.auth.RecaptchaVerifier(captchaArea, {
        size: 200,
        callback: () => {
          onSignInSubmit();
        },
      });
      appVerifier.render().then(() => {
        setLoadingCaptcha(false);
      });
    }
  }, [captchaArea]);

  const onSignInSubmit = () => {
    receiveCode();
  };

  const receiveCode = () => {
    setErrorVerify(false);
    firebase
      .auth()
      .signInWithPhoneNumber(phone, appVerifier)
      .then((confirmationResult) => {
        console.log('receiveCode confirmationResult', confirmationResult);
        window.confirmationResult = confirmationResult;

        interval = setInterval(() => {
          if (secondRef > 0) {
            setSeconds((old) => old - 1);
            secondRef -= 1;
          } else {
            clearInterval(interval);
          }
        }, 1000);
        setIsShowInputVerify(true);
      })
      .catch((err) => {
        console.log('receiveCode err', err);
        dispatch(showError(err.message));
      });
  };

  const onConfirm = () => {
    setErrorVerify(false);
    if (typeReceive === 1) {
      setLoadingVerify(true);
      window.confirmationResult
        .confirm(code)
        .then(() => {
          handleVerify(code);
        })
        .catch((error) => {
          console.log(error);
          setErrorVerify(true);
        })
        .then(() => {
          setLoadingVerify(false);
        });
    } else {
      handleVerifyOtpVoice(code);
    }
  };

  const resetCount = () => {
    if (interval) clearInterval(interval);
    setSeconds(60);
    secondRef = 60;
  };

  const resendCode = () => {
    if (seconds > 0) return;
    setCode("");
    setTypeReceive(1);
    resetCount();
    setLoadingCaptcha(true);
    setIsShowInputVerify(false);
  };

  const handleCodeResquestVoice = async () => {
    // if (seconds > 0) return;
    setCode("");
    setTypeReceive(2);
    const params = { phone: phone, type: 2 };
    try {
      dispatch(setLoading(true));
      await homeApi.getOTP(params);
      dispatch(setLoading(false));
      resetCount();

      interval = setInterval(() => {
        if (secondRef > 0) {
          setSeconds((old) => old - 1);
          secondRef -= 1;
        } else {
          clearInterval(interval);
        }
      }, 1000);
    } catch (error) {
      dispatch(showError(error.message));
      dispatch(setLoading(false));
    }
  };

  const setErrorVerify = (isError) => {
    dispatch(verifyNumberPhoneError(isError));
  };

  return (
    <FormDialog isOpen>
      <div className={classes.wrapAccount}>
        <div className={classes.account}>
          <Tooltip title="Quay lại">
            <IconButton
              onClick={() => handleBackVerify()}
              color="secondary"
              className="btn_icon"
            >
              <ArrowBackIcon />
            </IconButton>
          </Tooltip>
          <div className={classes.right}>
            <h4>{t("phone_verification.title")}</h4>

            <p>
              {t("phone_verification.description")}{" "}
              <span className={classes.number}>{phone}</span>
            </p>
          </div>
        </div>
        {isShowInputVerify ? (
          <FormControl className={classes.form}>
            <Flex column align="center" justify="center" className="mt-4">
              <>
                {typeReceive === 1 && (
                  <div className="my-3">
                    <img style={{ width: 250 }} src={smsExample} />
                    <p className="fz-14 mb-3 my-0 text-center">
                      {t("phone_verification.sample_sms")}
                    </p>
                  </div>
                )}
                <OtpInput
                  value={code}
                  inputStyle={{
                    width: 45,
                    height: 42,
                    border: "solid 1px #FFF",
                    borderRadius: 4,
                    fontWeight: 700,
                    fontSize: 20,
                    outline: "none",
                    color: "#05ae34",
                    margin: "0 8px",
                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                  }}
                  onChange={(otp) => setCode(otp)}
                  numInputs={typeReceive === 1 ? 6 : 4}
                  separator={<span>-</span>}
                />
                {errorVerify && (
                  <p className={classes.errorCode}>{t("noti.otp")}</p>
                )}
                <ButtonLoading
                  variant="contained"
                  color="primary"
                  className="btn--rounder mt-4 bg__primary"
                  title={t("common.btn.verify")}
                  disabled={
                    typeReceive === 1 ? code?.length < 6 : code.length < 4
                  }
                  onClick={() => onConfirm()}
                  type="button"
                  isLoading={loadingVerify}
                />

                <p className="mt-4">
                  {t("phone_verification.not_received_otp")}
                </p>
                <p className="mt-1">
                  <span
                    className={styles.resend}
                    style={{ cursor: seconds > 0 ? "not-allowed" : "pointer" }}
                    onClick={resendCode}
                  >
                    {t("phone_verification.send_again")} ({seconds}s)
                  </span>
                  {i18n.language?.substr(0, 2) === "vi" && (
                    <>
                      <span className="mx-2">{t("phone_verification.or")}</span>
                      <span
                        style={{
                          cursor: seconds > 0 ? "not-allowed" : "pointer",
                        }}
                        className={styles.resend}
                        onClick={() => handleCodeResquestVoice()}
                      >
                        {t("phone_verification.send_code_via_call")} ({seconds}
                        s)
                      </span>
                    </>
                  )}
                </p>
              </>
            </Flex>
          </FormControl>
        ) : (
          <Flex align="center" column className="mt-3">
            {loadingCaptcha && (
              <Skeleton variant="rect" width={300} height={74} />
            )}
            <div ref={onRefChange}></div>
          </Flex>
        )}
      </div>
    </FormDialog>
  );
};

export default DialogVerifyPhone;
