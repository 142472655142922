import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import SearchIcon from "@material-ui/icons/Search";
import InputAdornment from "@material-ui/core/InputAdornment";
import Input from "@material-ui/core/Input";
import moment from "moment";
import types from "../constants/types";
import Pagination from "@material-ui/lab/Pagination";
import { TextField } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const headCells = [
  // { id: "is", numeric: false, disablePadding: false, label: "ID" },
  {
    id: "name",
    numeric: true,
    disablePadding: true,
    label: "overall.lastest_campaign.campaign_name",
  },
  {
    id: "fanpage",
    numeric: true,
    disablePadding: true,
    label: "overall.lastest_campaign.page_name",
  },
  {
    id: "author",
    numeric: true,
    disablePadding: true,
    label: "overall.lastest_campaign.creator",
  },
  {
    id: "createdAt",
    numeric: true,
    disablePadding: true,
    label: "overall.lastest_campaign.create_time",
  },
  {
    id: "status",
    numeric: true,
    disablePadding: true,
    label: "overall.lastest_campaign.status",
  },
  {
    id: "timeStart",
    numeric: true,
    disablePadding: true,
    label: "overall.lastest_campaign.start_time",
  },
  // {
  //   id: "timeEnd",
  //   numeric: true,
  //   disablePadding: true,
  //   label: "Thời gian kết thúc",
  // },
  {
    id: "numberCus",
    numeric: true,
    disablePadding: true,
    label: "overall.lastest_campaign.customer",
  },
  {
    id: "totalMess",
    numeric: true,
    disablePadding: true,
    label: "overall.lastest_campaign.message",
  },
  {
    id: "messSuccss",
    numeric: true,
    disablePadding: true,
    label: "overall.lastest_campaign.success",
  },
  {
    id: "messFail",
    numeric: true,
    disablePadding: true,
    label: "overall.lastest_campaign.failed",
  },
];

function EnhancedTableHead(props) {
  const { t } = useTranslation();
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
          >
            <TableSortLabel hideSortIcon={true}>
              {t(headCell.label)}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: "15px 15px 15px 0px",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: "100%",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  ".pagination": {
    "& .MuiTablePagination-input": {
      width: 70,
    },
  },

  wrapperSearch: {
    width: 500,
  },
  buttonSearch: {
    width: 150,
    position: "absolute",
    right: "15px",
  },
  noData: {
    color: "rgba(0,0,0,.5)",
    fontWeight: "bold",
    textAlign: "center",
    paddingBottom: 15,
  },
}));
let timeout = 0;

export default function EnhancedTable(props) {
  const { t } = useTranslation();

  const { data, handleGetCampaign, getCampaign, isPagination, isSearch } =
    props;
  const rows = (data && data.content) || [];
  const classes = useStyles();

  const [page, setPage] = React.useState(types.PAGINATION.PAGE);
  const [dense, setDense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(types.PAGINATION.SIZE);
  const [search, setSearch] = useState("");

  // useEffect(() => {
  //   const params = {
  //     page: page,
  //     size: rowsPerPage,
  //     search: search ? search : undefined,
  //     sort: "createdAt,desc",
  //   };
  //   getCampaign(params);
  // }, []);

  useEffect(() => {
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      handleSubmitSearch();
    }, 300);
  }, [search]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    handleGetCampaign({
      page: newPage,
      size: rowsPerPage,
      search: search ? search : undefined,
      sort: "createdAt,desc",
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, types.PAGINATION.SIZE));
    setPage(types.PAGINATION.PAGE);
    handleGetCampaign({
      page: types.PAGINATION.PAGE,
      size: parseInt(event.target.value, types.PAGINATION.SIZE),
      search: search ? search : undefined,
      sort: "createdAt,desc",
    });
  };

  const handleChange = (event, value) => {
    setPage(value);
    handleGetCampaign({
      page: value - 1,
      size: rowsPerPage,
      search: search ? search : undefined,
      sort: "createdAt,desc",
    });
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const handleSearch = (key, value) => {
    switch (key) {
      case "search":
        setSearch(value);
        break;
      default:
        break;
    }
  };

  const handleSubmitSearch = () => {
    const params = {
      page: types.PAGINATION.PAGE,
      size: types.PAGINATION.SIZE,
      sort: "createdAt,desc",
    };
    if (search) params.search = `name|pageNameText==${search}`;
    getCampaign(params);
    setRowsPerPage(types.PAGINATION.SIZE);
    setPage(types.PAGINATION.PAGE);
  };
  const onKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSubmitSearch();
    }
  };

  const onViewCampain = (id) => {
    window.open(
      types.URL.FACEBOOK +
        `&token=${localStorage.getItem("token") || null}&campaign=${id}`,
      "_blank"
    );
  };

  return (
    <>
      {isSearch && (
        <div className={classes.wrapperSearch}>
          <div className="mt-4 pr-3 w-100">
            <TextField
              variant="outlined"
              size="small"
              onChange={(event) => handleSearch("search", event.target.value)}
              placeholder={t("campaign_management.search")}
              className="w-100 bg-white"
              id="input-with-icon-textfield"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </div>
      )}
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <TableContainer>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
              aria-label="enhanced table"
            >
              <EnhancedTableHead classes={classes} />
              <TableBody>
                {(rows || []).map((row, index) => {
                  if (row.totalCus === 0) {
                    return null
                  }
                  return (
                    
                    <TableRow
                      className="row__custom"
                      role="checkbox"
                      tabIndex={-1}
                      key={index}
                      onClick={() => onViewCampain(row.id)}
                    >
                      {/* <TableCell align="right">{row.id}</TableCell> */}
                      <TableCell align="left">{row.name}</TableCell>
                      <TableCell align="right">{row.pageName}</TableCell>
                      <TableCell align="right">{row.userFullName}</TableCell>
                      <TableCell align="right">
                        {moment(row.createdAt).format("DD/MM/YYYY HH:mm")}
                      </TableCell>
                      {/* <TableCell align="right">{row.nameText}</TableCell> */}
                      <TableCell align="right">
                        {_.upperCase(row.status) ===
                        _.upperCase(types.STATUS.DONE)
                          ? t(
                              "overall.lastest_campaign.campaign_status.complete"
                            )
                          : _.upperCase(row.status) ===
                            _.upperCase(types.STATUS.PENDING)
                          ? t("update.pending")
                          : t("overall.campaign_and_message.sending")}
                      </TableCell>
                      <TableCell align="right">
                        {moment(row.beginTime).format("DD/MM/YYYY HH:mm")}
                      </TableCell>
                      <TableCell align="right">{row.totalCus}</TableCell>
                      <TableCell align="right">{row.totalMes}</TableCell>
                      <TableCell align="right">{row.totalSuccess}</TableCell>
                      <TableCell align="right">{row.totalFail}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          {rows.length == 0 && (
            <p className="py-4 text-muted text-center">{t("update.no_data")}</p>
          )}
          {isPagination && rows.length > 0 && (
            <>
              <TablePagination
                rowsPerPageOptions={[types.PAGINATION.SIZE, 20, 30, 50]}
                component="div"
                count={parseInt(data && data.totalElements)}
                rowsPerPage={rowsPerPage}
                page={page}
                labelRowsPerPage={t("update.display")}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                className={"pagination"}
              />
              <Pagination
                count={Math.ceil((data && data.totalElements) / rowsPerPage)}
                page={page == 0 ? page + 1 : page}
                onChange={handleChange}
              />
            </>
          )}
        </Paper>
        {/* {rows.length > 0 && (
          <FormControlLabel
            control={<Switch checked={dense} onChange={handleChangeDense} />}
            label="Thay đổi khoảng cách hiển thị gần"
          />
        )} */}
      </div>
    </>
  );
}

EnhancedTable.propTypes = {
  data: PropTypes.array,
  search: PropTypes.string,
  isSearch: PropTypes.bool,
  isPagination: PropTypes.bool,
  getCampaign: PropTypes.func,
  handleGetCampaign: PropTypes.func,
};

EnhancedTable.defaultProps = {
  isPagination: true,
  isSearch: true,
  handleGetCampaign: (f) => f,
  getCampaign: (f) => f,
  data: [],
  search: null,
};
