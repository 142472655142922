import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import HomeView from "./HomeView";
import * as actionsHome from "./HomeActions";
import * as selectorsLogin from "../Login/LoginSelectors";
import * as selectorsHome from "./HomeSelectors";
import * as selectorsApp from "../App/AppSelectors";
import * as actionsCampaign from "../Campaign/CampaignActions";
import * as selectorsCampaign from "../Campaign/CampaignSelectors";
// import * as selectorsHome from "./HomeSelectors";
const mapStateToProps = (state) => {
  return {
    getAccount: selectorsLogin.getAccount(state),
    verifyNumberPhone: selectorsHome.getVerifyNumberPhone(state),
    share: selectorsHome.share(state),
    user: selectorsApp.user(state),
    dashboard: selectorsHome.dashboard(state),
    campaign: selectorsCampaign.campaign(state),
    verifyNumberPhoneError: selectorsHome.verifyNumberPhoneError(state),
    loading: selectorsApp.loading(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateUser: (data, uid) => dispatch(actionsHome.updateUser(data, uid)),
  onVerifyNumberPhone: (verify, data, uid) =>
    dispatch(actionsHome.onVerifyNumberPhone(verify, data, uid)),
  onVerifyNumberPhoneVoice: (verify, data, uid) =>
    dispatch(actionsHome.onVerifyNumberPhoneVoice(verify, data, uid)),
  onGetOtp: (data) => dispatch(actionsHome.onGetOtp(data)),
  onWhatYouNeed: () => dispatch(actionsHome.onWhatYouNeed()),
  onDashboard: () => dispatch(actionsHome.onDashboard()),
  getCampaign: (params) => dispatch(actionsCampaign.getCampaign(params)),
});
export const selectShare = mapStateToProps;

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HomeView)
);
