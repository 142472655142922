import React from "react";
import { Button, Dialog, DialogContent, TextField } from "@material-ui/core";
import Flex from "components/Flex";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { onAddTags } from "views/Customer/CustomerActions";
import { selectAddTagLoading } from "views/Customer/CustomerSelectors";
import { useTranslation } from "react-i18next";

const AddTags = ({ handleClose, open = true, fanpage_selected }) => {
  const dispatch = useDispatch();
  const [messageAddTag, setMessageAddTag] = useState();
  const [txtValue, setTxtValue] = useState();
  const loading = useSelector(selectAddTagLoading);

  const { t } = useTranslation();

  const handleSubmitAddTag = () => {
    const allTags =
      fanpage_selected?.bombotTags?.length > 0
        ? fanpage_selected?.bombotTags
        : [];
    if (!/\S/.test(txtValue)) {
      setMessageAddTag(t("update.noti.enter_tag"));
    } else {
      setMessageAddTag("");
      const checkAddTag = (obj) => obj.name === txtValue;
      const checkTagExist = allTags.some(checkAddTag);
      if (checkTagExist) {
        setMessageAddTag(t("update.noti.tag_existed"));
      } else {
        const filtered = allTags.filter(function (el) {
          return el.id !== "not_in_tag";
        });
        let dataBombotTag = [...filtered, { name: txtValue }];
        let facebookTags =
          fanpage_selected && fanpage_selected.fbTags
            ? fanpage_selected && fanpage_selected.fbTags
            : [];
        const params = {
          id: fanpage_selected.id,
          name: fanpage_selected.name,
          fbTags: facebookTags,
          bombotTags: dataBombotTag,
        };
        dispatch(onAddTags(params));
      }
    }
  };

  const changeInput = (event) => {
    setTxtValue(event.target.value);
  };

  return (
    <Dialog onClose={handleClose} open={open} maxWidth="lg">
      <div className="p-4">
        <TextField
          className="input_tag"
          onChange={changeInput}
          id="filled-basic"
          label={t("customer_management.add_bombot_tag.insert_tag_name")}
          variant="outlined"
          size="small"
        />
        {messageAddTag && (
          <p className="fz-12 text-danger ml-2 mt-1">{messageAddTag}</p>
        )}
      </div>
      <Flex className="mt-0 px-4  pb-3" justify="flex-end">
        <Button
          onClick={handleClose}
          size="small"
          className="btn--rounder btn--primary"
        >
          {t("common.btn.close")}
        </Button>
        <Button
          size="small"
          disabled={loading}
          onClick={handleSubmitAddTag}
          className="btn--rounder btn--primary--filled ml-2"
        >
          <Flex align="center">
            {loading && <CircularProgress size={12} color="white" />}
            <p className="my-0 ml-2">{t("common.btn.create")}</p>
          </Flex>
        </Button>
      </Flex>
    </Dialog>
  );
};

export default AddTags;
