import { createSlice } from "@reduxjs/toolkit";
import initialState from "../../configs/redux/initialState";

const sidebarReducer = createSlice({
  name: "sidebar",
  initialState: initialState.sidebarReducer,
  reducers: {
    setMenu: (state, action) => {
      state.menu = action.payload;
    },
    setDehaze: (state, action) => {
      state.dehaze = action.payload;
    },
    
  }
});

export const {
    setMenu,
    setDehaze
} = sidebarReducer.actions;

export default sidebarReducer.reducer;
