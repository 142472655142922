import {
    makeStyles,
  } from "@material-ui/core";
  import Flex from "components/Flex";
  import React, { useCallback, useEffect, useState } from "react";
  import OtpInput from "react-otp-input";
  import FormDialog from "../../../../components/FormDialog";
  import ArrowBackIcon from "@material-ui/icons/ArrowBack";
  import styles from "../../styles.module.scss";
  import firebase from "firebase";
  import { useDispatch, useSelector } from "react-redux";
  import ButtonLoading from "components/ButtonLoading";
  import { Skeleton } from "@material-ui/lab";
  import { verifyNumberPhoneError } from "views/Home/HomeReducer";
  import { selectErrorVerifyPhone } from "views/Home/HomeSelectors";
  import homeApi from "api/homeApi";
  import { setLoading } from "views/App/AppReducer";
  import { showError } from "stores/alert/slice.alert";
  import smsExample from "../../../../assets/images/code.jpg";
  import { useTranslation } from "react-i18next";
  import IMAGES from "../../../../../src/images";

  import { useTheme } from '@mui/material/styles';
  import Button from '@mui/material/Button';
  import { useHistory } from "react-router-dom";
  
  
  
  
  const DialogAlertPoints = ({name, point, handleClose}) => {
    const [giftPoints, setGiftPoints] = useState(0);
    const [alertGiftBox, setAlertGiftBox] = useState(true);
    const history = useHistory();
    const theme = useTheme();
  const useStyles = makeStyles((theme) => ({
    account: {
      display: "flex",
      alignItems: "center",
      background: "#f0f0f0",
      padding: 15,
    },
    right: {
      marginLeft: 15,
    },
    wrapAccount: {
      padding: 15,
    },
    form: {
      width: "100%",
    },
    number: {
      fontWeight: "bold",
    },
    errorCode: {
      margin: "10px 0px 0px 10px",
      fontSize: "14px",
      color: "red",
      textAlign: "center",
    },
    giftBox:{
      padding: "20px 50px !important",
      "& .center":{
        textAlign: "center"
      },
      "& li":{
        listStyle: "none",
        padding: "7px"
      },
      "& .textUsed":{
        color: "#05AE36",
      },
      "& .feeText":{
        color: "#f6a801"
      },
      "& .grey":{
        color: "#fff",
        background: "grey",
        "& :hover":{
          color: "#fff",
          background: "grey",
        }
      },
      "& .grey:hover":{
        color: "#fff",
        background: "grey"
      },
      "& .line-button":{
        padding: "3px"
      }
    },
  }));

    const classes = useStyles();
    const { i18n, t } = useTranslation();

    const handleMenu = () => {
      history.push("/quan-ly-tai-khoan?openPoits=1");
    };

    const handleClosePopup = () => {
      localStorage.setItem("alertGiftBox", false);
      handleClose();
    };
    return (
      <FormDialog width="600px" isOpen handleClose={handleClosePopup}>
        <ol className={classes.giftBox}>
            <li className="center"><img src={IMAGES.GIFT_BOX} width="150px"/></li>
            <li>{t("account_management.service_information.helloText")} <strong>{name}</strong></li>
            <li>{t("account_management.service_information.finishText")} <strong className="textUsed">{point<250?1:(point<500?3:6)} {t("account_management.service_information.monthText")}</strong> <strong className="feeText"> {t("account_management.service_information.feeText")}</strong> <strong className="textUsed"> {t("account_management.service_information.usedText")}</strong></li>
            <li>{t("account_management.service_information.pointCost")} {point}{t("account_management.service_information.receiverText")}</li>
            <li className="center">
              <Button sx={{ borderRadius: '14px', width:'150px' }} size="small" variant="contained" color="success" onClick={() => handleMenu()}> {t("account_management.service_information.giftExchange")} </Button>
              <p className="line-button"></p>
              <Button sx={{ borderRadius: '14px', width:'150px' }} size="small" variant="contained" className="grey" onClick={() => handleClosePopup()}> {t("account_management.service_information.giftExchangeAfter")} </Button>
            </li>
        </ol>
      </FormDialog>
      
    );
  };
  
  export default DialogAlertPoints;
  