import homeApi from "../../api/homeApi";
import {
  verifyNumberPhone,
  share,
  dashboard,
  verifyNumberPhoneError,
} from "./HomeReducer";
import { setUser, setLoading } from "../App/AppReducer";
import _ from "lodash";
import { showError } from "stores/alert/slice.alert";

export function updateUser(data, uid) {
  return (dispatch) => {
    dispatch(setLoading(true));
    return homeApi.updateUser(data, uid).then(
      (res) => {
        dispatch(setUser(res));
        dispatch(setLoading(false));
        return res;
      },
      (error) => {
        dispatch(setLoading(false));
        return error;
      }
    );
  };
}

export function onVerifyNumberPhone(verify, data, uid) {
  return (dispatch) => {
    dispatch(setLoading(true));
    return dispatch(
      updateUser(
        {
          ...data,
          verify: true,
        },
        uid
      )
    );
  };
}

export function onVerifyNumberPhoneVoice(verify, data, uid) {
  const params = {
    phone: verify.phone,
    otp: verify.otp,
  };
  return (dispatch) => {
    dispatch(setLoading(true));
    return homeApi.verifyNumberPhone(params).then(
      (res) => {
        dispatch(setLoading(false));
        dispatch(verifyNumberPhoneError(false));

        dispatch(verifyNumberPhone(true));
        return dispatch(updateUser(data, uid));
      },
      () => {
        dispatch(setLoading(false));
        if (verify.otp) {
          dispatch(verifyNumberPhoneError(true));
        }
        dispatch(verifyNumberPhone(false));
      }
    );
  };
}

export function onGetOtp(data) {
  const params = {
    phone: data.phone,
    type: data.type,
  };
  return (dispatch) => {
    dispatch(setLoading(true));
    dispatch(verifyNumberPhoneError(false));
    dispatch(setLoading(false));
    return homeApi.getOTP(params).then(
      (res) => {
        dispatch(setLoading(false));
      },
      (error) => {
        dispatch(showError(error.message));
        dispatch(setLoading(false));
      }
    );
  };
}

export function onWhatYouNeed() {
  const params = {
    fields: "user,users,package,history,version,versions,business,province",
  };
  return (dispatch) => {
    dispatch(setLoading(true));
    return homeApi.getWhatYouNeed(params).then(
      (res) => {
        dispatch(share(res));
        dispatch(setLoading(false));
      },
      (error) => {
        dispatch(setLoading(false));
      }
    );
  };
}

export function onDashboard() {
  return (dispatch) => {
    dispatch(setLoading(true));
    return homeApi.getDashboard().then(
      (res) => {
        dispatch(dashboard(res));
        dispatch(setLoading(false));
      },
      (error) => {
        dispatch(setLoading(false));
      }
    );
  };
}
