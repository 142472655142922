import { createSlice } from "@reduxjs/toolkit";
import initialState from "../../configs/redux/initialState";

const homeReducer = createSlice({
  name: "home",
  initialState: initialState.homeReducer,
  reducers: {
    verifyNumberPhone: (state, action) => {
      state.verifyNumberPhone = action.payload;
    },
    share: (state, action) => {
      state.share = action.payload;
    },
    dashboard: (state, action) => {
      state.dashboard = action.payload;
    },
    verifyNumberPhoneError: (state, action) => {
      state.verifyNumberPhoneError = action.payload;
    },
    
  }
});

export const {
  verifyNumberPhone,
  share,
  dashboard,
  verifyNumberPhoneError
} = homeReducer.actions;

export default homeReducer.reducer;
