import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import LoginView from "./LoginView";
import * as actionsLogin from "./LoginActions";
import * as selectorsLogin from "./LoginSelectors";
import * as selectorsApp from "../App/AppSelectors";
const mapStateToProps = (state) => {
  return {
    getAccount: selectorsLogin.getAccount(state),
    statusLogined: selectorsLogin.statusLogined(state),
    forgotPassword: selectorsLogin.forgotPassword(state),
    loading: selectorsApp.loading(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  login: (ref) => dispatch(actionsLogin.login(ref)),
  handleSubmitEmail: (params) => dispatch(actionsLogin.onSubmitEmail(params)),
  onSubmitForgotPassword: (params) =>
    dispatch(actionsLogin.onSubmitForgotPassword(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginView);
