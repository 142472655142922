import axiosClient from "./axiosClient";
import urlApi from "./url";
const fanpageApi = {
  get: (params) => {
    return axiosClient.get(`${urlApi.fanpageApi.reportPage}`, { params });
  },
  fanPageDetail: (params) => {
    return axiosClient.post(`${urlApi.fanpageApi.fanPageDetail}`, params);
  },
  getDetailFanpage: (id) => {
    return axiosClient.get(`/api/v1.0/fanPage/${id}`);
  },
};

export default fanpageApi;
