// import { useHistory } from "react-router-dom";
import appApi from "../../api/appApi";
import { setLoading, setShare, setUser } from "./AppReducer";
import homeApi from "api/homeApi";
// const history = useHistory();
export function onGetUser(uid) {
  return (dispatch) => {
    return appApi.getUser(uid).then(
      (res) => {
        dispatch(setUser(res));
        // history.push(pathKeys.HOME);
      },
      (error) => {
        throw error;
      }
    );
  };
}

export function onWhatYouNeed() {
  const params = {
    fields: "user,users,package,history,version,versions,business,province",
  };
  return (dispatch) => {
    dispatch(setLoading(true));
    return homeApi.getWhatYouNeed(params).then(
      (res) => {
        dispatch(setShare(res));
        dispatch(setLoading(false));
      },
      (error) => {
        dispatch(setLoading(false));
      }
    );
  };
}
