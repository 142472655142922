import React from "react";
import { Controller } from "react-hook-form";
import { MenuItem, Select } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const BaseSelect = ({
  errors,
  name,
  options,
  defaultValue,
  control,
  style,
  ...props
}) => {
  const { t } = useTranslation(0);
  return (
    <>
      <Controller
        name={name}
        control={control}
        color="secondary"
        defaultValue={defaultValue}
        render={({ field }) => {
          return (
            <Select
              {...props}
              {...field}
              variant="outlined"
              placeholder={t("update.form.plh_select")}
              style={{
                ...style,
                height: "40px",
              }}
            >
              {options.map((opt, index) => (
                <MenuItem key={index} value={opt.value}>
                  {opt.label}
                </MenuItem>
              ))}
            </Select>
          );
        }}
      />
      {errors && errors[name] && (
        <p className="text-danger fz-12 mt-1">{errors[name]?.message}</p>
      )}
    </>
  );
};

export default BaseSelect;
