import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import _ from "lodash";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import moment from "moment";
import cn from "classnames";
import ButtonComponent from "./Button";
import Avatar from "@material-ui/core/Avatar";
import FormDialog from "./FormDialog";
import { useTranslation } from "react-i18next";
const headCells = [
  {
    id: "avatar",
    numeric: true,
    disablePadding: true,
    label: "account_management.linked_account.avatar",
  },
  {
    id: "accountName",
    numeric: true,
    disablePadding: true,
    label: "account_management.linked_account.name",
  },
  {
    id: "sdt",
    numeric: true,
    disablePadding: true,
    label: "account_management.linked_account.phone",
  },
  {
    id: "email",
    numeric: true,
    disablePadding: true,
    label: "account_management.linked_account.email",
  },
  {
    id: "lastTime",
    numeric: true,
    disablePadding: true,
    label: "account_management.linked_account.last_logged_in",
  },
  {
    id: "action",
    numeric: true,
    disablePadding: true,
    label: "account_management.linked_account.action",
  },
];

function EnhancedTableHead(props) {
  const { t } = useTranslation();
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
          >
            <TableSortLabel hideSortIcon={true}>
              {t(headCell.label)}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: "15px 15px 15px 0px",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: "100%",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  noData: {
    color: "rgba(0,0,0,.5)",
    fontWeight: "bold",
    textAlign: "center",
    paddingBottom: 15,
  },
  buttonIEA: {
    width: "100%",
    textAlign: "right",
    marginRight: "15px",
    paddingRight: "15px",
    "& button": {
      marginLeft: 15,
    },
  },
  avatar: {
    margin: "0px auto",
  },
  titlePopup: {
    textAlign: "center",
    color: "#05AE34",
  },
  wrapButtonPopup: {
    textAlign: "center",
  },
}));

export default function ListHistoryjs(props) {
  const { data, handleInviteCode, handleUnlink, messageUnlink, statusUnlink, messageInviteError } =
    props;
  const rows = (data && data.users) || [];
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(true);
  const [unlink, setUnlink] = React.useState({});
  const [isUnlink, setIsUnlink] = React.useState(false);
  const [message, setMessage] = useState("");
  const { t } = useTranslation();
  useEffect(() => {
    if (!statusUnlink) {
      setMessage(messageUnlink.message);
    } else if (statusUnlink) {
      setIsUnlink(false);
    }
  }, [messageUnlink, statusUnlink, messageInviteError]);

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const handleSubmitInviteCode = () => {
    handleInviteCode();
  };

  const handleSubmitUnlink = (row) => {
    setIsUnlink(true);
    setUnlink(row);
    setMessage("");
  };
  const handleConfirmUnlink = () => {
    handleUnlink(unlink);
    // setIsUnlink(false);
  };
  const handleCloseUnlink = () => {
    setIsUnlink(false);
  };
  return (
    <>
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <TableContainer>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
              aria-label="enhanced table"
            >
              <EnhancedTableHead classes={classes} />
              <TableBody>
                {(rows || []).map((row, index) => {
                  return (
                    <TableRow role="checkbox" tabIndex={-1} key={index}>
                      <TableCell align="right">
                        {/* {row.avatar && <img src={row.avatar} />} */}
                        {row.avatar ? (
                          <Avatar
                            className={classes.avatar}
                            alt={row.name}
                            src={row.avatar}
                          />
                        ) : (
                          <Avatar className={classes.avatar} alt={row.name} />
                        )}
                      </TableCell>
                      <TableCell align="right">{row.name}</TableCell>
                      <TableCell align="right">{row.phone}</TableCell>
                      <TableCell align="right">{row.email}</TableCell>
                      <TableCell align="right">
                        {moment(row.lastLogin).format("DD/MM/YYYY HH:mm")}
                      </TableCell>
                      <TableCell align="right">
                        <ButtonComponent
                          id="disconnect"
                          title={t("common.btn.unlink")}
                          className="btn--rounder"
                          backgroundColor="#BDBDBD"
                          color="white"
                          handleClickButton={() => handleSubmitUnlink(row)}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          {rows.length == 0 && (
            <p className="py-4 text-muted text-center">{t("update.no_data")}</p>
          )}
          {/* <TablePagination
          rowsPerPageOptions={[5, 10, 20, 50]}
          component="div"
          count={}
          rowsPerPage={rowsPerPage}
          page={page}
          labelRowsPerPage={"Hiển thị"}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          className={"pagination"}
        /> */}
        </Paper>
        {/* {rows.length > 0 && (
        <FormControlLabel
          control={<Switch checked={dense} onChange={handleChangeDense} />}
          label="Thay đổi khoảng cách hiển thị gần"
        />
      )} */}
      </div>
      <div className={classes.buttonIEA}>
        <ButtonComponent
          title={t("common.btn.add_account")}
          id="add_acount"
          backgroundColor="#05AE34"
          color="white"
          className="btn--rounder"
          handleClickButton={() => handleSubmitInviteCode()}
        />
      </div>

      <FormDialog
        isOpen={isUnlink}
        // handleClose={() => handleCloseInviteCodePopup()}
      >
        <div className={cn(classes.wrapChangePassword, "p-4")}>
          {/* <h4 className={classes.titlePopup}>
            Xác nhân
          </h4> */}
          <p style={{ textAlign: "center" }} className="mb-2 fz-15">
            {t("account_management.linked_account.unlink")}
          </p>
          {message && (
            <p style={{ color: "red", textAlign: "center" }} className="mb-0">
              {t("update.error_unlink")}
            </p>
          )}
          <div className="text-center mt-4">
            <ButtonComponent
              className="mr-2"
              title={t("common.btn.cancel")}
              backgroundColor="#BDBDBD"
              color="white"
              handleClickButton={() => handleCloseUnlink()}
            />
            <ButtonComponent
              title={t("common.btn.confirm")}
              backgroundColor="#05AE34"
              color="white"
              handleClickButton={() => handleConfirmUnlink()}
            />
          </div>
        </div>
      </FormDialog>
    </>
  );
}
