import React from "react";
import { TextField } from "@material-ui/core";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

const BaseInput = ({ errors, name, ...props }) => {
  const { t } = useTranslation();
  return (
    <>
      <Controller
        size="small"
        name={name}
        {...props}
        render={({ field }) => {
          return (
            <TextField
              {...props}
              {...field}
              variant="outlined"
              placeholder={t("update.form.plh_enter")}
              size="small"
            />
          );
        }}
        color="secondary"
      />
      {errors && errors[name] && (
        <p className="text-danger fz-12 mt-1">{errors[name]?.message}</p>
      )}
    </>
  );
};

export default BaseInput;
