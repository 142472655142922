import { createSelector } from "@reduxjs/toolkit";
export const verifyNumberPhone = (state) => state.homeReducer.verifyNumberPhone;
export const share = (state) => state.homeReducer.share;
export const dashboard = (state) => state.homeReducer.dashboard;
export const verifyNumberPhoneError = (state) =>
  state.homeReducer.verifyNumberPhoneError;
export const selectErrorVerifyPhone = (state) =>
  state.homeReducer.verifyNumberPhoneError;

export const getVerifyNumberPhone = createSelector(
  [verifyNumberPhone],
  (verify) => {
    return verify;
  }
);

export const selectUser = createSelector(share, (share) => {
  return share.user;
});
