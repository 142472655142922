import React from "react";
import { Button, CircularProgress } from "@material-ui/core";
import styles from "./styles.module.scss";
import cn from "classnames";
import Flex from "components/Flex";

const BaseSelectFile = ({
  loading,
  onChange,
  className,
  classCssLoading,
  fileNameSelected = "",
  ...rest
}) => {
  if (loading)
    return (
      <Button
        variant="outlined"
        color="secondary"
        className={cn([styles.file_loading, classCssLoading, "py-2"])}
      >
        <CircularProgress color="secondary" size={23} />
      </Button>
    );
  return (
    <Flex className={styles.container} align="center">
      <input
        {...rest}
        type="file"
        className={cn([styles.file_input, className])}
        onChange={onChange}
      />
      <p className={cn(styles.file__name, "text-truncate")}>
        {fileNameSelected}
      </p>
    </Flex>
  );
};

export default BaseSelectFile;
