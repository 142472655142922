import { createSelector } from "@reduxjs/toolkit";

export const selectConfig = (state) => state.configs;

export const selectGuideList = createSelector(
  selectConfig,
  (state) => state.guides
);

export const selectGuideListByKey = (key) =>
  createSelector(selectGuideList, (state) => (state ? state[key] : null));

export const selectLoading = createSelector(
  selectConfig,
  (state) => state.loading
);
export const selectCountries = createSelector(
  selectConfig,
  (state) => state.countries
);

export const selectCountriesOnly = createSelector(selectConfig, (state) =>
  state.countries.map((e) => e.countryName)
);
