import inviteApi from "../../api/inviteApi";
import { setConfirmInviteCode } from "./InviteReducer";
import { setLoading } from "../App/AppReducer";
import _ from "lodash";

export function confirmInviteCode(code) {
  return (dispatch) => {
    dispatch(setLoading(true));
    return inviteApi.confirmInvite(code).then(
      (res) => {
        dispatch(setConfirmInviteCode(res));
        dispatch(setLoading(false));
      },
      (error) => {
        dispatch(setConfirmInviteCode(false));
        dispatch(setLoading(false));
      }
    );
  };
}
