import axiosClient from "./axiosClient";
import axios, { post } from "axios";
import urlApi from "./url";
const customerApi = {
  get: (params) => {
    return axiosClient.get(`${urlApi.customerApi.customer}`, { params });
  },
  create: (params) => {
    return axiosClient.post(`${urlApi.customerApi.create}`, params);
  },
  addCampainByPart: (params) => {
    return axiosClient.post(`${urlApi.customerApi.addCampainByPart}`, params);
  },
  createCustomer: (params) => {
    return axiosClient.post(`${urlApi.customerApi.createCustomer}`, params);
  },
  downloadExcel: () => {
    return axiosClient.get(
      `${urlApi.customerApi.customer}/customer-template.xlsx`
    );
  },
  edit: (params) => {
    return axiosClient.put(
      `${urlApi.customerApi.customer}/${params.id}`,
      params
    );
  },
  addMultiCustomersBombotTags: (payload) => {
    return axiosClient.post(
      `${urlApi.customerApi.addMultiCustomersBombotTags}`,
      payload
    );
  },
  removeMultiCustomersBombotTags: (payload) => {
    return axiosClient.delete(
      `${urlApi.customerApi.addMultiCustomersBombotTags}`,
      {
        data: payload,
      }
    );
  },
  export: (api_path, params) => {
    return axiosClient.post(`${urlApi.customerApi.customer}/${api_path}`, params, {
      responseType: "arraybuffer",
    });
  },
  import: (params) => {
    const id = params.fanpage && params.fanpage.id;
    const formData = new FormData();
    formData.append("file", params.file);
    formData.append("type", params.type);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        authorization: localStorage.getItem("token") || undefined,
      },
    };
    return post(
      `${urlApi.customerApi.customer}/${id}/import`,
      formData,
      config
    );
  },
};

export default customerApi;
