import { withStyles } from "@material-ui/core";
const styles = (theme) => ({
  "@global": {
    /**
     * Disable the focus outline, which is default on some browsers like
     * chrome when focusing elements
     */
    "*:focus": {
      outline: 0,
    },
    ".MuiChip-deleteIcon": {
      width: 15,
    },
    ".MuiTableCell-root": {
      fontFamily: "Roboto",
    },
    ".MuiAutocomplete-tag": {
      marginTop: 0,
      marginLeft: "0px",
      marginRight: "5px",
      // marginBottom: "10px",
    },
    ".MuiListItem-button:hover": {
      background: "#05AE34",
      color: "white",
      "& .MuiListItemIcon-root": {
        color: "white",
      },
    },
    ".MuiPagination-root": {
      textAlign: "right",
      float: "right",
      top: "20px",
      position: "relative",
    },
    ".MuiTablePagination-actions": {
      display: "none",
    },
    ".MuiTablePagination-root": {
      float: "left",
      // height: "120px",
      marginTop: "15px",
    },
    ".MuiPaper-root": {
      boxShadow: "0 0 2rem 0 rgba(136,152,170,.15)!important",
    },
    // ".MuiAutocomplete-root .MuiFormControl-root": {
    //   background: "none",
    //   height: "auto",
    // },
    // ".MuiAutocomplete-root .MuiInputLabel-outlined": {
    //   marginTop: -40,
    //   fontSize: 14,
    //   background: "white",
    // },
    // ".MuiAutocomplete-root .MuiInputLabel-outlined.MuiInputLabel-shrink": {
    //   transform: "initial",
    //   marginTop: -20,
    //   left: 0,
    // },
    // ".MuiInputBase-input": {
    //   boxSizing: "inherit !important",
    // },
    // ".MuiAutocomplete-root": {
    //   width: "100% !important",
    //   marginBottom: 10,
    //   "& .MuiInputBase-input": {
    //     fontSize: 16,
    //   },
    //   "& .MuiInputLabel-outlined": {
    //     marginLeft: -5,
    //     fontSize: 16,
    //     background: "white",
    //   },
    //   "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
    //     transform: "translate(17px, -1px) scale(0.75)",
    //   },
    //   "& .MuiFormControl-root": {
    //     background: "white",
    //     border: 0,
    //     transition: "box-shadow .15s ease",
    //     height: 40,
    //     padding: "5px 0px",
    //     color: "rgba(0, 0, 0, 0.87)",
    //     fontSize: 14,
    //     borderRadius: 5,
    //     width: "100%",
    //   },
    //   "& .MuiOutlinedInput-notchedOutline": {
    //     borderColor: "#fff",
    //   },
    // },
    ".MuiDrawer-modal": {
      width: 250,
      "& .MuiBackdrop-root": {
        backgroundColor: "initial",
        position: "relative",
      },
    },
    ".MuiTableCell-sizeSmall": {
      padding: "16px 4px !important",
    },
    ".MuiTableCell-head": {
      padding: "16px 4px",
      // backgroundColor: "var(--success)",
      textAlign: "center",
      // color: "var(--white)",
      fontWeight: "600",
    },
    ".MuiTableCell-body": {
      textAlign: "center",
    },
    // ".MuiSelect-select.MuiSelect-select": {
    //   background: "none",
    //   display: "flex",
    //   alignItems: "center",
    // },
    // ".MuiSelect-select": {
    //   fontSize: 16,
    // },
    ".MuiListItemIcon-root": {
      minWidth: "40px",
    },
    // ".MuiFormLabel-root.Mui-focused": {
    //   color: "black",
    // },
    // ".MuiFilledInput-root:hover": {
    //   background: "none",
    // },
    // ".MuiInput-root, .MuiInputBase-root": {
    //   background: "white",
    //   boxShadow: "0 1px 3px rgb(50 50 93 / 15%), 0 1px 0 rgb(0 0 0 / 2%)",
    //   border: 0,
    //   transition: "box-shadow .15s ease",
    //   minHeight: 40,
    //   color: "rgba(0, 0, 0, 0.87)",
    //   fontSize: 14,
    //   borderRadius: 5,
    //   width: "100%",
    //   padding: 0,
    // },
    // ".MuiAutocomplete-inputRoot .MuiAutocomplete-input": {
    //   paddingTop: "0px !important",
    //   paddingBottom: "0px !important",
    //   // minHeight: 40
    // },
    ".MuiTablePagination-input": {
      width: 70,
    },
    ".MuiTablePagination-root .MuiTablePagination-input + .MuiTablePagination-caption":
      {
        display: "none",
      },
    // ".MuiFilledInput-input": {
    //   padding: "27px 12px 10px 2px",
    //   fontSize: 16,
    // },
    // ".MuiAutocomplete-noOptions": {
    //   display: "none",
    // },
    // ".MuiInputLabel-filled.MuiInputLabel-shrink": {
    //   transform: "translate(12px, 7px) scale(0.7)",
    // },
    // ".MuiInputLabel-filled": {
    //   transform: "translate(12px, 14px) scale(1)",
    //   fontSize: 16,
    // },
    // ".MuiInput-underline:before, .MuiFilledInput-underline:before": {
    //   borderBottom: "0px !important",
    //   "&.Mui-focused": {
    //     borderBottom: "0px !important",
    //   },
    // },
    ".MuiTablePagination-toolbar": {
      paddingLeft: 0,
    },
    // ".MuiInput-underline:after, .MuiFilledInput-underline:after": {
    //   borderBottom: "0px !important",
    //   "&.Mui-focused": {
    //     borderBottom: "0px !important",
    //   },
    // },
    ".text-white": {
      color: theme.palette.common.white,
    },
    ".listItemLeftPadding": {
      paddingTop: `${theme.spacing(1.75)}px !important`,
      paddingBottom: `${theme.spacing(1.75)}px !important`,
      paddingLeft: `${theme.spacing(4)}px !important`,
      [theme.breakpoints.down("sm")]: {
        paddingLeft: `${theme.spacing(4)}px !important`,
      },
      "@media (max-width:  420px)": {
        paddingLeft: `${theme.spacing(1)}px !important`,
      },
    },
    ".container": {
      width: "100%",
      paddingRight: theme.spacing(4),
      paddingLeft: theme.spacing(4),
      marginRight: "auto",
      marginLeft: "auto",
      [theme.breakpoints.up("sm")]: {
        maxWidth: 540,
      },
      [theme.breakpoints.up("md")]: {
        maxWidth: 720,
      },
      [theme.breakpoints.up("lg")]: {
        maxWidth: 1170,
      },
    },
    ".row": {
      display: "flex",
      flexWrap: "wrap",
      marginRight: -theme.spacing(2),
      marginLeft: -theme.spacing(2),
    },
    ".container-fluid": {
      width: "100%",
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      marginRight: "auto",
      marginLeft: "auto",
    },
    ".lg-mg-top": {
      marginTop: `${theme.spacing(20)}px !important`,
      [theme.breakpoints.down("md")]: {
        marginTop: `${theme.spacing(18)}px !important`,
      },
      [theme.breakpoints.down("sm")]: {
        marginTop: `${theme.spacing(16)}px !important`,
      },
      [theme.breakpoints.down("xs")]: {
        marginTop: `${theme.spacing(14)}px !important`,
      },
    },
    ".lg-mg-bottom": {
      marginBottom: `${theme.spacing(20)}px !important`,
      [theme.breakpoints.down("md")]: {
        marginBottom: `${theme.spacing(18)}px !important`,
      },
      [theme.breakpoints.down("sm")]: {
        marginBottom: `${theme.spacing(16)}px !important`,
      },
      [theme.breakpoints.down("xs")]: {
        marginBottom: `${theme.spacing(14)}px !important`,
      },
    },
    ".lg-p-top": {
      paddingTop: `${theme.spacing(20)}px !important`,
      [theme.breakpoints.down("md")]: {
        paddingTop: `${theme.spacing(18)}px !important`,
      },
      [theme.breakpoints.down("sm")]: {
        paddingTop: `${theme.spacing(16)}px !important`,
      },
      [theme.breakpoints.down("xs")]: {
        paddingTop: `${theme.spacing(14)}px !important`,
      },
    },
    "button:-moz-focusring, [type='button']:-moz-focusring, [type='reset']:-moz-focusring, [type='submit']:-moz-focusring":
      {
        outline: "none",
      },
  },
});

function globalStyles() {
  return null;
}

export default withStyles(styles, { withTheme: true })(globalStyles);
