import React, { useEffect, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import SidebarContainer from "../Sidebar";
import HeaderContainer from "../Header";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import ChangeLanguage from "components/ChangeLanguage";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "red",
  },
  sidebar: {
    width: 250,
    float: "left",
    height: "100vh",
    overflowY: "auto",
    boxShadow: "0 0 2rem 0 rgba(136,152,170,.15)!important",
    background: "white",
    position: "fixed",
    top: 0,
    left: 0,
    "-webkit-transition": "all 0.1s ease-in",
  },
  sidebarDehaze: {
    width: 60,
    float: "left",
    height: "100vh",
    overflowY: "auto",
    boxShadow: "0 0 2rem 0 rgba(136,152,170,.15)!important",
    background: "white",
    position: "fixed",
    top: 0,
    left: 0,
    zIndex: 10,
    "-webkit-transition": "all 0.09s ease-out",
  },
  contentRightDehaze: {
    width: "calc(100% - 60px)",
    float: "right",
    paddingLeft: 15,
    marginTop: 76,
    paddingBottom: 60,
    "-webkit-transition": "all 0.1s ease-out",
  },
  contentRight: {
    width: "calc(100% - 250px)",
    float: "right",
    paddingLeft: 15,
    marginTop: 76,
    paddingBottom: 60,
    "-webkit-transition": "all 0.09s ease-in",
  },
  footerDehaze: {
    height: 70,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingRight: 15,
    color: "#05ae34",
    left: -265,
    bottom: "0px",
    width: "100%",
    background: "#F6F9FC",
    "-webkit-transition": "all 0.1s ease-out",
  },
  footer: {
    height: 70,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingRight: 15,
    color: "#05ae34",
    left: "265px",
    bottom: "0px",
    width: "calc(100% - 265px)",
    background: "#F6F9FC",
    position: "fixed",
    "-webkit-transition": "all 0.09s ease-in",
  },
  right: {
    "& a": {
      marginLeft: 15,
      color: "#05ae34",
      textDecoration: "none",
    },
  },
  contactDehaze: {
    position: 'fixed',
    bottom: '30px',
    right: '15px',
  },
  boxMail: {
    position: 'absolute',
    bottom: '55px'
  },
  call: {
    position: 'absolute',
    bottom: '110px'
  }
}));

const MainLayoutView = (props) => {
  const { children, dehaze } = props;
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  const configPage = useMemo(() => {
    if (i18n.language?.substring(0, 2) === "kh") {
      return {
        page_id: "102636822160260",
        app_id: "4087907114572119",
      };
    } else {
      return {
        page_id: "103634791744953",
        app_id: "4087907114572119",
      };
    }
  }, [i18n.language]);

  return (
    <div className={classes.root}>
      <div className={cn(dehaze ? classes.sidebarDehaze : classes.sidebar)}>
        <SidebarContainer />
      </div>
      <div
        className={cn(
          dehaze ? classes.contentRightDehaze : classes.contentRight
        )}
      >
        <HeaderContainer />
        <div className={classes.content}>{children}</div>
        <div className={cn(dehaze ? classes.footerDehaze : classes.footer)}>
          <div className={classes.left}>
            &copy; 2021{" "}
            <span style={{ fontWeight: "bold", color: "rgb(5, 174, 52)" }}>
              @Bombot.vn
            </span>
          </div>
          <div className={classes.right}>
            <ChangeLanguage isUser={true} />
            <a href="https://bombot.vn/" target="_blank">
              {t("footer.home")}
            </a>
            <a href="https://partner.bombot.vn/" target="_blank">
              {t("footer.affiliate")}
            </a>
            <a href="https://bombot.vn/chinh-sach-dich-vu/" target="_blank">
              {t("footer.policy")}
            </a>
            <a href="https://bombot.vn/dieu-khoan-bao-mat" target="_blank">
              {t("footer.private_policy")}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

MainLayoutView.propTypes = {};

MainLayoutView.defaultProps = {};

export default MainLayoutView;
