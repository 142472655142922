
import { useRef, useEffect } from 'react';

function useEffectIgnoreFirst(callback: Function, dependencies: Array) {
    const isFirstRun = useRef(true);
    useEffect(() => {
        if (isFirstRun.current) {
            isFirstRun.current = false;
            return;
        }
        return callback()
    }, dependencies)
}

export default useEffectIgnoreFirst