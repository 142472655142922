import campaignApi from '../../api/campaignApi';
import { setLoading } from "../App/AppReducer";
import {
    setCampaign,
} from "./CampaignReducer";

export function getCampaign(params) {
  return dispatch => {
    dispatch(setLoading(true));
    return campaignApi.get(params).then(
      res => {
        dispatch(setCampaign(res));
        dispatch(setLoading(false));
      },
      error => {
        dispatch(setLoading(false));
      }
    );
  };
}