import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { useDispatch, useSelector } from "react-redux";
import { selectAlert } from "stores/alert/selectors.alert";
import { resetAlert } from "stores/alert/slice.alert";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const AlertProvider = ({ children }) => {
  const dispatch = useDispatch();

  const alert = useSelector(selectAlert);

  const reset = () => dispatch(resetAlert());

  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={alert?.message}
        autoHideDuration={3000}
        onClose={reset}
      >
        <Alert onClose={reset} severity={alert?.type}>
          {alert?.message}
        </Alert>
      </Snackbar>
      {children}
    </div>
  );
};

export default AlertProvider;
