import { createSelector } from "@reduxjs/toolkit";
export const menu = state => state.sidebarReducer.menu;
export const dehaze = state => state.sidebarReducer.dehaze;

export const getMenu = createSelector(
  [menu],
  (menu) => {
    return menu;
  }
);
