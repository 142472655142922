import * as React from 'react';
import { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import IMAGES from "../../../src/images";
import Loading from "../../components/Loading";

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { Button, CardActionArea, CardActions } from '@mui/material';
import { Snackbar, TablePagination } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";


function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const DialogPolicyPointView = (props) => {
  const {
        onWhatYouNeed,
        whatYouNeed,
        onRedeemPoints,
        redeemPoints,
        loading,
      } = props;
    const [typeMessage, setTypeMessage] = useState(null);

    useEffect(() => {
        const params = {
          fields: "package,user",
        };
        if(!whatYouNeed.package){
          onWhatYouNeed(params);
        } 
      }, []);

      useEffect(() => {
        
      if(redeemPoints && redeemPoints.message){
        setTypeMessage("error");
      } else if(redeemPoints && redeemPoints.giftPoint){
        setTypeMessage("success");
      }
      }, [redeemPoints]);



  const { t } = useTranslation();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  

  const useStyles = makeStyles((theme) => ({
    MuiAppBarColorPrimary: {
        color: "#000  !important",
        backgroundColor: "#fff  !important",
        textAlign: "center"
    },
    PointPannel: {
        textAlign: "justify",
        "& ol":{
          paddingLeft: "0px !important"
        },
        "& li":{
          listStyle: "none"
        },
        "& td, th": {
          border: "1px solid #dddddd",
          textAlign: "left",
          padding: "8px"
        },
        "& a":{
          color: "#05AE34"
        }
        
        
    },
    PointPannel3: {
      "& img":{
        padding: "30px"
      },
      "& ul":{
        padding: 0
      },
      "& li":{
        listStyle: "none"
      },
      "& table":{
        width: "100%",
        textAlign: "center",
        "& td": {
          textAlign: "-webkit-center"
        }
      },
      "& .MuiCardActions-root":{
        display: "block !important"
      },
      "& .point":{
        color: "#05AE34",
        fontWeight: "bold"
      }
    },
    giftPointLogo: {
      width: "80px"
    },
    giftPoint: {
      "& strong": {
        color: "#fd7e14"
      }
    },
    error: {
      color: "#fd7e14"
    },
    columTask:{
      width: "60%"
    },
    columReward:{
      width: "40%"
    }

  }));

  const classes = useStyles();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };


  const handleRedeemPoints = (type) =>{
    const params = {
      type: `${type}`,
    };
    onRedeemPoints(params);
  }

  return (
    <Box sx={{ bgcolor: 'background.paper'}}>
      {loading && <Loading />}
      {typeMessage && (
          <Snackbar
            open={typeMessage != null}
            anchorOrigin={{
              horizontal: "center",
              vertical: "top",
            }}
            autoHideDuration={6000}
            // onClose={() => setTypeMessage(null)}
          >
            <Alert onClose={() => {}} severity={typeMessage}>
              {typeMessage === "error"
                ? t("account_management.tab_policy_point.exchangeError")
                : t("account_management.tab_policy_point.exchangeSuccess")}
            </Alert>
          </Snackbar>
        )}
      <AppBar position="static" className={classes.MuiAppBarColorPrimary}>
        <Tabs
          value={value}
          onChange={handleChange}
          TabIndicatorProps={{
            style: {
              backgroundColor: "#05AE34"
             }
            }}
          textColor="inherit"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab label={t("account_management.tab_policy_point.tab_title1")} {...a11yProps(0)} />
          <Tab label={t("account_management.tab_policy_point.tab_title2")} {...a11yProps(1)} />
          <Tab label={t("account_management.tab_policy_point.tab_title3")} {...a11yProps(2)} />
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction} className={classes.PointPannel}>
        <ol>
            <li>{t("account_management.tab_policy_point.specialProgram")} <strong>“{t("account_management.tab_policy_point.getMoreFree")}”</strong> {t("account_management.tab_policy_point.whoHaveBeen")} </li>
            <li>✊ {t("account_management.tab_policy_point.haveAnOppor")}</li>
            <li>💎 {t("account_management.tab_policy_point.missions")} </li>
            <li>🎁 {t("account_management.tab_policy_point.getMoreTime")}</li>
            <br/>
            <li><strong>{t("account_management.tab_policy_point.scope")}: </strong></li>
            <li>{t("account_management.tab_policy_point.including")}</li>
            <br/>
            <li><strong>{t("account_management.tab_policy_point.specialNote")}: </strong></li>
            <li>{t("account_management.tab_policy_point.notAllowed")}</li>
            <br/>
            <li><strong>{t("account_management.tab_policy_point.rules")}: </strong> </li>
            <li>{t("account_management.tab_policy_point.pointEarned")}</li>
            <li>{t("account_management.tab_policy_point.thePoints")}</li>
            
        </ol>
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction} className={classes.PointPannel}>
        <table>
          <tr>
            <th className={classes.columTask}>{t("account_management.tab_policy_point.task")}</th>
            <th className={classes.columReward}>{t("account_management.tab_policy_point.rewardPoints")}</th> 
          </tr>
          <tr>
            <td>
            <strong>{t("account_management.tab_policy_point.dailyLogin")}</strong>
            <br/>
            {t("account_management.tab_policy_point.logInbombot")}</td>
            <td>{t("account_management.tab_policy_point.pointDay")}</td>
          </tr>
          <tr>
            <td>
            <strong>{t("account_management.tab_policy_point.reviewBombot")}</strong>
              <br/>
              {t("account_management.tab_policy_point.postARenew")}
              <br/>
              {t("account_management.tab_policy_point.afterPosting")}
              
              <a target="_blank" href='https://m.me/103634791744953?ref=nv_review_cs_tich_diem'> {t("account_management.tab_policy_point.afterPosting1")} </a>
              {t("account_management.tab_policy_point.afterPosting2")}
              </td> 
            <td>{t("account_management.tab_policy_point.pointPost")}</td> 
          </tr>

          <tr>
            <td>
            <strong>{t("account_management.tab_policy_point.spread")}</strong>
              <br/>
              {t("account_management.tab_policy_point.inviteFriend")}: <strong>  <a target="_blank" href={"https://bombot.vn/?ref=" + whatYouNeed?.user?.myAffId}>https://bombot.vn/?ref={whatYouNeed?.user?.myAffId}</a></strong>
              <br/>
              {t("account_management.tab_policy_point.scroreIs")}</td> 
            <td>{t("account_management.tab_policy_point.pointSuccessful")}</td> 
          </tr>

          <tr>
            <td>
            <strong>{t("account_management.tab_policy_point.receiveRewards")}</strong>
              <br/>
              {t("account_management.tab_policy_point.friendWho")}:
              <strong> <a target="_blank" href={"https://bombot.vn/?ref=" + whatYouNeed?.user?.myAffId}>https://bombot.vn/?ref={whatYouNeed?.user?.myAffId}</a></strong></td>
            <td>
            {t("account_management.tab_policy_point.pointPayment")}
              <br/>
              {t("account_management.tab_policy_point.comission")}</td>
          </tr>
        </table>
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction} className={classes.PointPannel3}>
        <p className={classes.giftPoint} >{t("account_management.service_information.giftPoint")}: <strong>{(redeemPoints && redeemPoints.giftPoint) ? redeemPoints.giftPoint : whatYouNeed?.package?.giftPoint}</strong></p>
        <p>{t("account_management.tab_policy_point.choiseGift")}</p>
        <br/>
        
        <table>
          <tr>
          <td><Card sx={{ maxWidth: 150 }}>
              <CardActionArea>
                <CardMedia
                  component="img"
                  height="140"
                  image={IMAGES.LOGO_MINI}
                />
                <CardContent>
                  <Typography color="#05AE34" gutterBottom variant="h5" component="div">
                  {t("account_management.tab_policy_point.cost1")}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                  {t("account_management.tab_policy_point.giftCost1")}
                  </Typography>
                </CardContent>
                <CardActions>
                <CardActions>
                <Button  sx={{ borderRadius: '14px' }}  size="small" variant="outlined" color="success"
                onClick={() => handleRedeemPoints(1)}
                >
                  {t("account_management.tab_policy_point.tab_title3")}
                </Button>
              </CardActions>
              </CardActions>
              </CardActionArea>
            </Card>
          </td>

          <td><Card sx={{ maxWidth: 150 }}>
              <CardActionArea>
                <CardMedia
                  component="img"
                  height="140"
                  image={IMAGES.LOGO_MINI}
                />
                <CardContent>
                  <Typography color="#05AE34" gutterBottom variant="h5" component="div">
                  {t("account_management.tab_policy_point.cost3")}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                  {t("account_management.tab_policy_point.giftCost3")}
                  </Typography>
                </CardContent>
                <CardActions>
                <CardActions>
                <Button sx={{ borderRadius: '14px' }} size="small" variant="outlined" color="success"
                onClick={() => handleRedeemPoints(3)} >
                   {t("account_management.tab_policy_point.tab_title3")}
                </Button>
              </CardActions>
              </CardActions>
              </CardActionArea>
            </Card>
          </td>

          <td><Card sx={{ maxWidth: 150 }}>
              <CardActionArea>
                <CardMedia
                  component="img"
                  height="140"
                  image={IMAGES.LOGO_MINI}
                />
                <CardContent>
                  <Typography color="#05AE34" gutterBottom variant="h5" component="div">
                  {t("account_management.tab_policy_point.cost6")}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                  {t("account_management.tab_policy_point.giftCost6")}
                  </Typography>
                </CardContent>
                <CardActions>
                <CardActions>
                <Button  sx={{ borderRadius: '14px' }}  size="small" variant="outlined" color="success"
                onClick={() => handleRedeemPoints(6)} >
                  {t("account_management.tab_policy_point.tab_title3")}
                </Button>
              </CardActions>
              </CardActions>
              </CardActionArea>
            </Card>
          </td>
            
          </tr>
          
        </table>
        </TabPanel>
      </SwipeableViews>
    </Box>
  );
}

DialogPolicyPointView.propTypes = {};

DialogPolicyPointView.defaultProps = {};
export default  DialogPolicyPointView;