import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import AppView from "./AppView";
import * as actionsApp from "./AppActions";
import * as selectorsApp from "./AppSelectors";

const mapStateToProps = state => {
  return {
    user: selectorsApp.user(state),
  };
};

const mapDispatchToProps = dispatch => ({
    onGetUser: (uid) => dispatch(actionsApp.onGetUser(uid)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)((AppView))
);
