import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  type: 'info',
  message: null,
}

const alertSlice = createSlice({
  name: 'alert',
  initialState,
  reducers: {
    resetAlert: () => initialState,
    showError: (state, action) => {
      state.type = 'error'
      state.message = action.payload
    },
    showInfo: (state, action) => {
      state.type = 'info'
      state.message = action.payload
    },
    showSuccess: (state, action) => {
      state.type = 'success'
      state.message = action.payload
    },
  },
})

export const {
  resetAlert,
  showError,
  showInfo,
  showSuccess,
} = alertSlice.actions

export default alertSlice
