import { pathKeys } from "../../constants";
export default {
  appReducer: {
    user: {},
    loading: false,
  },
  loginReducer: {
    account: {},
    statusLogined: null,
    forgotPassword: null,
  },
  homeReducer: {
    verifyNumberPhone: false,
    share: [],
    dashboard: {},
    verifyNumberPhoneError: false,
  },
  sidebarReducer: {
    menu: `Tổng quan`,
    dehaze: false
  },
  campaignReducer: {},
  fanpageReducer: {
    fanpageFull: [],
  },
  settingAccountReducer: {
    messageUnlink: "",
    whatYouNeed: {},
    changePassword: null,
    inviteCode: {},
    unlink: false,
    messageInviteError: ""
  },
  dialogPolicyPointReducer:{
    whatYouNeed: {},
    redeemPoints: {},
  },
  customerReducer: {
    createCustomerSucss: false,
    customerAll: [],
    customer: null,
    detail: {},
    // edit: {},
    selected: {},
    fanpageSelected: {},
    tagFacebookSelectedAll: false,
    selectTagFacebook: {},
    setLoadingImport: false,
    importSuccess: false,
    fanPageDetail: {},
    customerCheck: [],
    messageImport: ""
  },
  inviteReducer: {
    confirmInviteCode: null,
  },
};
