import { createSelector } from "@reduxjs/toolkit";

const selectAppReducer = (state) => state.appReducer;
export const user = (state) => state.appReducer.user;
export const loading = (state) => state.appReducer.loading;

export const selectUser = createSelector(user, (user) => {
  return user;
});

export const selectShare = createSelector(selectAppReducer, (state) => {
  return state.share;
});
