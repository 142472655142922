import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import cn from "classnames";
import { Dialog } from "@material-ui/core";
import Flex from "components/Flex";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";
import {
  getIsGuideByKey,
  GUIDE_TYPE_ENUM,
  setIsGuideByKey,
} from "utils/helper";
import { useSelector } from "react-redux";
import { selectGuideListByKey } from "stores/config/config.selector";
import YouTube from "react-youtube";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "8px 24px",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const StepContent = ({ step, data }) => {
  if (!data?.length) return null;
  return (
    <Flex justify="center" align="center" column>
      <h1 className="fz-18 fw-700 mb-4">{data[step]?.title}</h1>
      <div className={styles.content}>
        <YouTube
          videoId={data[step]?.link_video.replace(
            "https://www.youtube.com/embed/",
            ""
          )}
        />
      </div>
    </Flex>
  );
};

export default function Introduce({ handleClose }) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const contentRef = useRef();
  const { t } = useTranslation();

  const introduceData = useSelector(
    selectGuideListByKey(GUIDE_TYPE_ENUM.video_introduce)
  );
  const [tourConfig, setTourConfig] = useState([]);

  useEffect(() => {
    if (
      getIsGuideByKey(GUIDE_TYPE_ENUM.video_introduce) &&
      introduceData?.length
    ) {
      setTourConfig(introduceData);
    }
  }, [introduceData]);

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const closeModal = () => {
    setTourConfig([]);
    setIsGuideByKey(GUIDE_TYPE_ENUM.video_introduce);
    handleClose();
  };

  const handleNext = () => {
    if (activeStep === tourConfig.length - 1) {
      closeModal();
    }
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Dialog
      maxWidth="xl"
      open={tourConfig?.length > 0}
      onClose={closeModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <div className={classes.root}>
        <Flex justify="center">
          <div className={styles.step}>
            <Stepper activeStep={activeStep}>
              {tourConfig.map((label, index) => {
                const stepProps = {};
                const labelProps = {};
                // if (isStepOptional(index)) {
                //   labelProps.optional = (
                //     <Typography variant="caption">Optional</Typography>
                //   );
                // }
                if (isStepSkipped(index)) {
                  stepProps.completed = false;
                }
                return (
                  <Step key={index} {...stepProps}>
                    <StepLabel {...labelProps}></StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </div>
        </Flex>
        <div>
          <StepContent step={activeStep} data={tourConfig} ref={contentRef} />
          <Flex justify="flex-end" align="center" className="mt-4">
            <Button
              disabled={activeStep === 0}
              onClick={handleBack}
              variant="contained"
              size="small"
              className={cn("btn--rounder", "fw-500")}
              color="default"
            >
              {t("update.back")}
            </Button>

            <Button
              variant="contained"
              size="small"
              onClick={handleNext}
              className={cn(
                "ml-2",
                "btn--rounder",
                "btn--primary--filled",
                "fw-500",
                "text-uppercase"
              )}
            >
              {activeStep === tourConfig.length - 1
                ? t("common.btn.close")
                : t("update.next")}
            </Button>
          </Flex>
        </div>
      </div>
    </Dialog>
  );
}
