export const FIELD_OPTIONS = [
  {
    label: "customer_management.list.uid",
    value: "uid",
  },
  {
    label: "customer_management.list.facebook_name",
    value: "name",
  },
  {
    label: "customer_management.list.gender",
    value: "gender",
  },
  {
    label: "customer_management.list.phone",
    value: "phone",
  },
  {
    label: "customer_management.list.facebook_tag",
    value: "fbTags",
  },
  {
    label: "customer_management.list.bombot_tag",
    value: "bombotTags",
  },
  {
    label: "customer_management.list.last_update_time",
    value: "lastInboxTime",
  },
  {
    label: "customer_management.list.last_inbox_time",
    value: "lastInboxTime"
  },
];
export const OPERATION_OPTION_NAME = [
  {
    label: "customer_management.customer_filter.contain",
    value: "contain",
  },
  {
    label: "customer_management.customer_filter.not_contain",
    value: "ncontain",
  },
];

export const OPERATION_TAGS_OPTION = [
  {
    label: "update.customer_filter.in",
    value: "in",
  },
  {
    label: "update.customer_filter.notin",
    value: "notin",
  },
];

export const GENDER_OPTION = [
  {
    label: "customer_management.customer_filter.is",
    value: "==",
  },
  {
    label: "customer_management.customer_filter.is_not",
    value: "!=",
  },
];
export const OPERATION_OPTION = [...GENDER_OPTION, ...OPERATION_TAGS_OPTION];

export const OPERATION_TIME_OPTION = [
  {
    label: "customer_management.customer_filter.range",
    value: "bw",
  },
];

export const OPERATION_PHONE_OPTION = [
  {
    label: "customer_management.customer_filter.is",
    value: "exists",
  },
];

export const GENDER_OPTIONS = [
  {
    label: "update.gender.male",
    value: "MALE",
  },
  {
    label: "update.gender.female",
    value: "FEMALE",
  },
  {
    label: "update.gender.other",
    value: "NEUTER",
  },
];

export const PHONE_OPTIONS = [
  {
    label: "update.customer_filter.in",
    value: "true",
  },
  {
    label: "update.customer_filter.notin",
    value: "false",
  },
];
